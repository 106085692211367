import React, { useContext, useEffect, useState } from "react";
import Navbar from "./Navbar";
import Studentnav from "../Student/Header/navbar";
import CounsellorNavbar from "../Counsellor/Header/Navbar";
import UserContext from "../../contexts/UserContext";

function Header() {
  const { userData, setUserData } = useContext(UserContext);
  // const [active, setActive] = useState(window.innerWidth > 991);
  // useEffect(() => {
  //   const handleResize = () => {
  //     setActive(window.innerWidth > 991);
  //   };
  //   window.addEventListener("resize", handleResize);

  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //   };
  // }, []);

  return (
    <>
      {userData === null ? (
        <Navbar />
      ) : userData.flag === "STUDENT" ? (
        <Studentnav />
      ) : userData.flag === "COUNSELLOR" ? (
        <CounsellorNavbar />
      ) : (
        <Navbar />
      )}
      {/* {active && <Sub />} */}
    </>
  );
}

export default Header;
