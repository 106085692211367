import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  Suspense,
} from "react";
import UserContext from "../../../contexts/UserContext";
import iimmgg from "../../../images/ARTIFICIALINTELLIGENCE.jpg";
import axios from "axios";
import { API } from "../../../api-config";
import { IoLocationSharp } from "react-icons/io5";
import Loader from "../../Loader/Loader";
import "../../../style/partials/Counsellor/_dashboard.scss";
import moment from "moment";
import { Rating } from "react-simple-star-rating";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import InfiniteCarousel from "react-leaf-carousel";
import SectionsHead from "../../Home/Heading";
const Counsellor = React.lazy(() => import("../Home/Counsellor"));

const CounsellorPortfo = () => {
  const { userData, setUserData } = useContext(UserContext);
  const location = useLocation();
  const { email } = location.state;
  const navigate = useNavigate();
  console.log(email);
  const [counsellorDetails, setCounsellorDetails] = useState("");
  const [ratingDetails, setRatingDetails] = useState([]);
  const [skills, setSkills] = useState([]);
  const [sessions, setSessions] = useState([]);
  const [feedbackDetails, setFeedbackDetails] = useState([]);
  const [photo, setPhoto] = useState(null);
  // console.log("USER DATA DETAILS", userData);
  const dashboardData = async () => {
    console.log(email);
    const { data } = await axios.post(`${API}/api/frontregi/portf`, {
      emailId: email,
    });
    console.log("DASHBOARD DETAILS", data);
    setCounsellorDetails(data?.response[0]?.firstDetails[0]);
    setSkills(data?.response[0]?.skillsunwinded[0]?.skills);
    setRatingDetails(data?.response[0]?.feedbackFacet);
    console.log(data.response[0]?.firstDetails[0]?.feedback);
    setFeedbackDetails(data?.response[0]?.firstDetails[0]?.feedback);
    setPhoto(
      (prevState) => data?.response[0].firstDetails[0].counsellor[0].profilePic
    );
    const updateUsers = async () => {
      let myData = await axios.post(`${API}/api/session/get-session`, {
        counsellorId: data?.response[0]?.firstDetails[0]._id,
      });
      console.log("COUNSELLOR SESSIONS", myData);
      setSessions(myData.data);
    };
    updateUsers();
  };

  useEffect(() => {
    dashboardData();
  }, []);
  return (
    <Fragment>
      {counsellorDetails && (
        <div className="container introduction d-flex">
          <div className="">
            <img
              src={photo ? photo : iimmgg}
              className="dashPic rounded-circle border-success"
              height={"160px"}
              style={{
                borderRadius: "50%",
                overflow: "hidden",
                display: "inline-block",
              }}
              alt="Profile Pic"
              loading="lazy"
            ></img>
          </div>
          <div className="profileDetails">
            <p>{counsellorDetails?.fName}</p>
            <p>{Math.ceil(counsellorDetails?.ratings[0]?.ratingstar)}</p>
            <p>{counsellorDetails?.ratings[0]?.totalsessions}</p>

            <p>
              <IoLocationSharp />
              {counsellorDetails?.counsellor[0]?.country}
            </p>
            <p>About Me : {counsellorDetails?.counsellor[0]?.description}</p>
            <p style={{ fontWeight: "600" }}>Skills</p>
            <div className="d-flex flex-wrap">
              {skills.map((item, index) => (
                <p
                  className="p-2 m-2"
                  style={{ border: "1px solid #000", borderRadius: "18px" }}
                  key={index}
                >
                  {item.skill}
                </p>
              ))}
            </div>
          </div>
        </div>
      )}
      {sessions && (
        <div className="sessions container">
          <div className="linepart"></div>
          <h5>Sessions</h5>
          <div className="d-flex flex-wrap">
            {sessions &&
              sessions.map((item, index) => (
                <div
                  style={{
                    border: "2px solid black",
                    borderRadius: "20px",
                    width: "fit-content",
                  }}
                  className="m-2 p-2"
                  onClick={() => {
                    navigate("/session-details", {
                      state: {
                        id: item._id,
                        bid: item.sessionNumber,
                        cost: item.sessionCost,
                        defaultCurrency: item.defaultCurrency
                          ? item.defaultCurrency
                          : "INR",
                      },
                    });
                  }}
                >
                  <p>{item.sessiontitle}</p>
                  <p>{`${item.sessionCost} ${item.defaultCurrency}`}</p>
                </div>
              ))}
          </div>
          <div className="linepart"></div>
        </div>
      )}
      <section className="sub-heading">
        <SectionsHead heading="Portfolio" />
        <div className="container">
          {counsellorDetails &&
          counsellorDetails.counsellor[0].portfolio.length > 0 ? (
            <div className="row">
              <InfiniteCarousel
                breakpoints={[
                  {
                    breakpoint: 500,
                    settings: {
                      slidesToShow: 1,
                      slidesToScroll: 1,
                    },
                  },
                  {
                    breakpoint: 991,
                    settings: {
                      slidesToShow: 2,
                      slidesToScroll: 2,
                    },
                  },
                  {
                    breakpoint: 1400,
                    settings: {
                      slidesToShow: 2,
                      slidesToScroll: 2,
                    },
                  },
                  {
                    breakpoint: 2000,
                    settings: {
                      slidesToShow: 3,
                      slidesToScroll: 3,
                    },
                  },
                ]}
                dots={false}
                showSides={true}
                sidesOpacity={0.5}
                sideSize={0.1}
                slidesToScroll={2}
                slidesToShow={2}
                scrollOnDevice={true}
              >
                {counsellorDetails.counsellor[0].portfolio.map(
                  (item, index) => {
                    return (
                      <div className="  d-flex justify-content-center ">
                        <div className="category-content-box ">
                          <div className="image-sub-headings">
                            <img src={item} alt="subhead" loading="lazy"></img>
                          </div>
                        </div>
                      </div>
                    );
                  }
                )}
              </InfiniteCarousel>
            </div>
          ) : (
            <p>No Portfolio Uploaded</p>
          )}
        </div>
      </section>
      <div className="protfolio"></div>
      {counsellorDetails && (
        <div className="reviews container ">
          <p style={{ fontWeight: "600", fontSize: "18px" }}>
            {`${counsellorDetails?.ratings[0]?.totalsessions}   Reviews`}
          </p>
          {ratingDetails?.map((item, index) => (
            <div className="container" key={index}>
              <div className="row">
                <div className="col-md-6">
                  <div className="well well-sm">
                    <div className="row">
                      <div className="col-xs-12 col-md-6">
                        <div className="row rating-desc">
                          <div className="col-xs-3 col-md-3 ">
                            <span className="fa fa-star"></span>
                            {item._id}
                          </div>
                          <div className="col-xs-8 col-md-9">
                            <div className="progress">
                              <div
                                className="progress-bar progress-bar-success"
                                style={{
                                  width: `${
                                    (Number(item.count) /
                                      Number(
                                        counsellorDetails?.ratings[0]
                                          ?.totalsessions
                                      )) *
                                    100
                                  }%`,
                                }}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <span className="col-md-3">{`(${item.count})`}</span>
                    </div>
                  </div>
                </div>
                <div className="col-md-3"></div>
              </div>
            </div>
          ))}
        </div>
      )}

      <div className="feedbacks container">
        {feedbackDetails &&
          feedbackDetails?.map((item, index) => (
            <div className="d-flex mt-5 mb-5 row">
              <div className="linepart"></div>
              <div className="col-2">
                <img
                  src={iimmgg}
                  style={{
                    height: "160px",
                    borderRadius: "10%",
                  }}
                  alt="reviews"
                  loading="lazy"
                  className="col-2 "
                ></img>
              </div>
              <div className="d-flex flex-column col-10">
                <p className="font-weight-bold">{`${item.studentId[0].fName}`}</p>
                <Rating initialValue={item.counsellorRating} readonly={true} />
                <p>{moment(new Date(item.createdAt)).fromNow()}</p>
                <div>{item.feedbackDesc}</div>
              </div>
            </div>
          ))}
      </div>
    </Fragment>
  );
};
export default CounsellorPortfo;
