import { React, useState, useEffect } from "react";
import profile from "../../images/test.png";
import Ratings from "../../images/rating.png";
import Star from "../../images/star.png";
import "../../style/partials/components/_counsellorpop.scss";
import { Modal } from "react-bootstrap";
import { API } from "../../api-config";
import axios from "axios";
import SectionsHead from "../Home/Heading";
import { Rating } from "react-simple-star-rating";
import InfiniteCarousel from "react-leaf-carousel";
import "..//..//style/partials/components/_counsellorsilder.scss";
import { Link } from "react-router-dom";

let pageNum = 0;
let ratingIds = [];
let totalcounsellors = [];
function Counsellorslider() {
  const [show, setShow] = useState(false);
  const [rs, setrs] = useState([]);
  const [cs, setcs] = useState();
  const [pagesCount, setPagesCount] = useState();
  const [photo, setPhoto] = useState(null);
  const handleClose = () => setShow(false);
  const [counsellors, setCounsellors] = useState();

  const loadCounsellors = async () => {
    ratingIds = [];

    const { data } = await axios.post(
      `${API}/api/counselor-profile/get-counselor`,
      { offset: pageNum }
    );
    setPagesCount(Math.ceil(data.userpros[0].totalCount / 8));

    setCounsellors(data.userpros[0].userpros);
    totalcounsellors = data.userpros[0].userpros;

    setcs(true);
  };
  useEffect(() => {
    try {
      loadCounsellors();
    } catch (error) {
      console.log("ERROR  " + error);
    }
  }, []);

  const loadMoreCounsellors = async () => {
    // ratingIds = [];
    pageNum = pageNum + 1;
    console.log("page number", pageNum, "PAGES COUNT", pagesCount);
    if (pageNum > pagesCount) {
      pageNum = pageNum - 1;
      return;
    } else {
      const { data } = await axios.post(
        `${API}/api/counselor-profile/get-counselor`,
        { offset: pageNum }
      );
      totalcounsellors = [...totalcounsellors, ...data.userpros[0].userpros];
      setCounsellors(totalcounsellors);
      console.log("TOTAL COUNSELLLOORORRSS", totalcounsellors);
    }
  };

  const handleShow = (_id) => {
    setShow(true);
    let y = counsellors.find((p) => p._id === _id);
    console.log("SELECTED FOR MODAL", y);
    setrs(y);
  };

  return (
    <div className="mt-5 mb-5">
    <SectionsHead heading="Our Top Counsellor" />
    <section className="top-counsellor">
      <section className="container sec-wid mt-6">
        <div className="row">
          {counsellors && (
            <InfiniteCarousel
              breakpoints={[
                {
                  breakpoint: 767,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                  },
                },
                {
                  breakpoint: 991,
                  settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                  },
                },
                {
                  breakpoint: 1366,
                  settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                  },
                },
                {
                  breakpoint: 2000,
                  settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                  },
                },
              ]}
              dots={false}
              showSides={true}
              sidesOpacity={0.5}
              sideSize={0.1}
              slidesToScroll={4}
              slidesToShow={4}
              scrollOnDevice={true}
              onNextClick={loadMoreCounsellors}
            >
              {counsellors &&
                counsellors?.map((x, index) => {
                  // console.log(x);
                  return (
                    <div
                      className="col couns-car our-top-counsellor-flex cursor-pointer"
                      key={`${x._id}_id_${index}`}
                    >
                      <div
                        className="top-counsellour-info-box shadow"
                        key={x._id}
                        onClick={(e) => handleShow(x._id)}
                      >
                        <div className="profile-img-top-counsellor">
                          <img
                            src={x?.profilePic ? x?.profilePic : profile}
                            className="w-100"
                            alt="profilePic"
                            loading="lazy"
                            height="60"
                            width="60"
                          />
                        </div>
                        <div className="card-info-ditels">
                          {x?.userId?.fName && (
                            <p key={`${x._id}name`}>
                              {x?.userId?.fName.charAt(0).toUpperCase() +
                                x?.userId?.fName.slice(1)}
                            </p>
                          )}{" "}
                          {x?.skills && (
                            <span key={x?._id} className="text-muted mb-1">
                              {x?.skills[0]?.skill
                                ? x?.skills[0]?.skill
                                : x?.skills}
                            </span>
                          )}
                          {x?.ratings && (
                            <div className="rating">
                              <Rating
                                readonly={true}
                                initialValue={x.ratings[0].ratingstar}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
            </InfiniteCarousel>
          )}
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton className="m-auto">
              <SectionsHead heading="Counsellor Details" />
            </Modal.Header>
            <div className=" counsellor modal-dialog-pop mt-0">
              <div className="modal-content-pop container" id="modalheader">
                <div className="modal-header">
                  <div className="modal-title shadow-sm" id="exampleModalLabel">
                    <div className="row   identy">
                      <div className="col-4 align-self-center text-center ">
                        <img
                          src={rs?.profilePic ? rs?.profilePic : profile}
                          alt="profile pic"
                          loading="lazy"
                          height="60"
                          width="60"
                        />
                      </div>
                      <div className="col-8">
                        <div>
                          <label>Counsellor Name</label>
                          <span className="counsellor-name-size">
                            {rs.userId?.fName + " " + rs.userId?.lName}
                          </span>
                          <div className="rating-pop">
                            {rs.ratings && rs.ratings[0].ratingstar > 0 ? (
                              <Rating
                                readonly={true}
                                initialValue={rs.ratings[0].ratingstar}
                              />
                            ) : (
                              <span>No rating yet</span>
                            )}
                          </div>

                          <div>
                            <label>Skills</label>
                            <div className="skills-name d-flex flex-wrap">
                              {rs.skills?.map((item, index) => {
                                if (index <= 3) {
                                  return (
                                    <span
                                      className="d-block"
                                      key={`${item._id}${index}`}
                                    >
                                      {item.skill},&nbsp;&nbsp;
                                    </span>
                                  );
                                }
                              })}
                            </div>
                          </div>
                          <div>
                            {rs && (
                              <Link
                                to="/portf"
                                state={{ email: rs?.userId?.email }}
                              >
                                See More
                              </Link>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div className="col-6 experience-fs">
                        <label>Experience</label>
                        <h5>{rs.experience} Year</h5>
                      </div>
                      <div className="col-6 Sessions-fs">
                        <label>Completed Sessions</label>
                        {rs?.ratings && <p>{rs.ratings[0].totalsessions}</p>}
                      </div>
                    </div>
                  </div>
                </div>
                {rs.description != "" && (
                  <div className="modal-body shadow-sm">
                    <div className="col-12 pl-2 pr-2">
                      <p>{rs.description}</p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Modal>
        </div>
      </section>
    </section>
    </div>
  );
}

export default Counsellorslider;
