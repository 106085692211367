import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { API } from "../../../api-config.js";
import User from "../../../contexts/UserContext";
import "../../../style/partials/Students/_upcomingSessionS.scss";
import { VscCalendar } from "react-icons/vsc";
import socket from "../../../socket/socketio";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";
import SectionsHead from "../../Home/Heading.js";

let throttle = false;
let offset = 0;
const UpcomingStudentSession = () => {
  const { userData } = useContext(User);
  const [data, setData] = useState("");
  const [len, setLength] = useState(0);
  const [isDisplayed, setIsDisplayed] = useState();
  const [sessions, setSessions] = useState([]);
  const navigate = useNavigate();

  const userActiveSessions = async () => {
    const userIden = userData.userId;
    const response = await axios.get(`${API}/api/upcomingSession/getSessions`, {
      params: { userIden, offset: offset },
    });
    console.log("RREESSPPOONNNSSEE", response.data.count);
    setSessions(response.data.allUpcoming);
    setLength(Math.ceil(response.data.count / 10));
    // console.log("socket", response);
    // socket.emit("sendMessage", "messages");
  };
  useEffect(() => {
    console.log(userData);
    if (!userData.isProfile) {
      navigate("/students");
    }
    socket.on(`completeCheck${userData.userId}`, () => {
      console.log("CAME FOR UPDATION");
      userActiveSessions();
    });
    return () => {
      socket.off(`completeCheck${userData.userId}`);
    };
  }, [socket, userData]);

  const sessionComplete = async (item, session, slotNumber) => {
    // console.log("CHECK ITEM ", item, session, slotNumber);
    try {
      // console.log("SLOT WISE", item.sessionDetails[0][slotNumber]);
      item.sessionDetails[0][slotNumber].paid = true;
      item.leftBal =
        item.leftBal - parseInt(item.sessionDetails[0][slotNumber].Price);
      // ADMIN WILL PAY to Counsellor for the slot , annd slot will be completed in both bidreq and upcoming session
      const response = await axios.post(
        `${API}/api/admin-wallet/payment`,
        {
          item: item,
          slotNumber: slotNumber,
        },
        {
          headers: {
            token: userData.token,
          },
        }
      );
      console.log("PAID TO ADMIN", response.data);
      socket.emit(`completeCheck`, { user: item.counsellorId._id });

      if (item.leftBal <= 0) {
        // debugger;
        // const addCompleted = await axios.post(
        //   `${API}/api/upcomingSession/completed`,
        //   { data: item }
        // );
        // console.log("Upcoming SESSION CREATEDS", addCompleted.data);
        alertify.success("Session Completed");
        navigate("/completed-session");
      }

      socket.emit("paymentDone", { userId: item.counsellorId._id });
      let notificationDetails = {
        msg: `Payment recieved for counselling on ${item.sessiontitle} to ${userData.fName}`,
        entity: "Payment",
      };
      const notificationResponse = await axios.post(
        `${API}/api/notifications/update`,
        {
          details: notificationDetails,
          userData: { _id: item.counsellorId._id },
        },
        {
          headers: { token: userData.token },
        }
      );
      setSessions((prevSessions) =>
        prevSessions.filter((se) => item._id !== se._id)
      );
      userActiveSessions();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    userActiveSessions();
  }, []);

  const toggleDisplay = (index) => {
    setIsDisplayed(index);
  };

  return (
    <>
      <section className="container mt-5 mb-5">
        <SectionsHead heading="Upcoming Sessions" />
        {sessions.length > 0 ? (
          sessions.map((item, index) => {
            // console.log("NOOFITEMS", item);
            return (
              <div
                className="counseller-dtl-card shadow-sm container shape"
                key={index}
                onClick={() => toggleDisplay(index)}
              >
                <div className="row">
                  <div className=" col-md-12 heading counselling-hover">
                    <div className="counselling-diteal-open">
                      <i className="fa-solid fa-star"></i>
                      <div>
                        <p>{`Counselling On ${item.sessiontitle} From ${item.counsellorId.fName} `}</p>
                        <p className="sessionCount">
                          Number Of Sessions : {item.sessionSlots.length}
                        </p>
                        <p className="sessionHour">1 Hour/Session</p>
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-1 col-md-1 calender">
                    <VscCalendar></VscCalendar>
                  </div> */}
                  <div className="container">
                    {index == isDisplayed ? (
                      <div className="row calender-content">
                        <div className=" counselD col-12 col-md-12">
                          <p>
                            <b>DESCRIPTION :</b>
                            {item.sessionDes}
                          </p>
                        </div>
                        {item.sessionSlots.map((items, indexed) => {
                          console.log(items);
                          const sessionElements = Object.entries(items).map(
                            ([key, session], indexes) => (
                              <div className="col-lg-3 col-md-6 d-flex justify-content-center">
                                <div key={key} className="session  shadow-sm">
                                  <h2>{session.sessionTitle}</h2>
                                  <p>Start Date: {session.startDate}</p>
                                  <p>Start Time: {session.fromHour}</p>
                                  {/* <p>To Hour: {session.toHour}</p> */}
                                  <p>Slot Description: {session.sesDes}</p>
                                  <div className="btn-div">
                                    {item.sessionDetails[0][indexes].paid && (
                                      <button className="completed">
                                        Completed
                                      </button>
                                    )}
                                    {item.sessionDetails[0][indexes]
                                      .requestPay &&
                                      !item.sessionDetails[0][indexes].paid && (
                                        <button
                                          className="completed"
                                          onClick={() => {
                                            if (throttle) {
                                              return;
                                            }
                                            throttle = true;
                                            sessionComplete(
                                              item,
                                              items,
                                              indexes
                                            );
                                            setTimeout(() => {
                                              throttle = false;
                                            }, 4000);
                                          }}
                                        >
                                          Accept Complete
                                        </button>
                                      )}
                                  </div>
                                </div>
                              </div>
                            )
                          );
                          return sessionElements;
                        })}
                      </div>
                    ) : null}
                  </div>
                </div>
                {/* {pagesCount > 1 && (
        <div className="container text-center">
          {direction > 1 && (
            <button
              className="index-btn mb-2 mt-2"
              onClick={() => {
                pageNum(direction - 1 >= 1 ? direction - 1 : 1);
              }}
            >
              <i className="fa-solid fa-chevron-left"></i>
            </button>
          )}
          {(() => {
            const buttons = [];

            if (pagesCount >= 3) {
              for (let i = -1; i < 2; i++) {
                const currentPageNum = accomodatePage(direction);
                buttons.push(
                  <button
                    key={currentPageNum + i}
                    className="index-btn mb-2 mt-2"
                    onClick={() => {
                      pageNum(currentPageNum + i);
                    }}
                  >
                    {currentPageNum + i}
                  </button>
                );
              }
            } else {
              for (let i = 0; i < pagesCount; i++) {
                buttons.push(
                  <button
                    key={i + 1}
                    className="index-btn mb-2 mt-2"
                    onClick={() => {
                      pageNum(i + 1);
                    }}
                  >
                    {i + 1}
                  </button>
                );
              }
            }
            return buttons;
          })()}

          {direction != pagesCount && (
            <button
              className="index-btn mb-2 mt-2"
              onClick={() => {
                pageNum(
                  direction + 1 <= pagesCount ? direction + 1 : pagesCount
                );
              }}
            >
              <i className="fa-solid fa-chevron-right"></i>
            </button>
          )}
        </div>
      )} */}
              </div>
            );
          })
        ) : (  <div className="row pt-3 pb-3"><h6 className="text-center">No Pending Session</h6></div>
        )}
        {len > 1 && (
          <div className="det-button mt-3">
            {offset > 0 && (
              <button
                className="btn btn-outline-primary custom-btn btn-8"
                onClick={() => {
                  if (offset <= 0) {
                    return;
                  } else {
                    offset -= 1;
                    userActiveSessions();
                  }
                }}
              >
                Prev
              </button>
            )}
            {offset + 1 < len && (
              <button
                className="btn btn-outline-primary custom-btn btn-8"
                onClick={() => {
                  if (offset >= len - 1) {
                    return;
                  } else {
                    console.log("LENGTH", len);
                    offset += 1;
                    userActiveSessions();
                  }
                }}
              >
                Next
              </button>
            )}
          </div>
        )}
      </section>
    </>
  );
};

export default React.memo(UpcomingStudentSession);
