import React, { Suspense, useContext, useEffect, lazy } from "react";
import SectionsHead from "../Home/Heading";
import Loader from "../Loader/Loader";
import Studentsession from "../Student/Studentsession/Studentsession";
import Counsellosession from "../Counsellor/Counsellorsession/Cousellorsession";
import UserContext from "../../contexts/UserContext";
import Login from "../form/Login";
import { useNavigate } from "react-router-dom";
const Skillslider = lazy(() => import("../sliders/SkillsSlider"));
const CategorySection = lazy(() => import("../sliders/CategorySection"));

const Counsellorslider = React.lazy(() =>
  import("../sliders/Counsellorslider")
);

function SelectCounselling() {
  const { userData } = useContext(UserContext);
  const Navigate = useNavigate();

  useEffect(() => {
    if (!userData.isProfile && userData.flag === "STUDENT") {
      Navigate("/students");
    } else if (!userData.isProfile && userData.flag === "COUNSELLOR") {
      Navigate("/counsellor");
    }
  }, [userData, Navigate]);
  let selection;

  if (userData.flag === "STUDENT") {
    selection = (
      <>
        <Suspense fallback={<Loader />}>
          <Skillslider />
          <Counsellorslider />
          <CategorySection />
          <Studentsession />
        </Suspense>
      </>
    );
  } else if (userData.flag === "COUNSELLOR") {
    selection = <Counsellosession />;
  } else {
    selection = <Login />;
  }
  return <div className="">{selection}</div>;
}

export default SelectCounselling;
