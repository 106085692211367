import React, { useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { API } from "../../api-config";

const Reset = () => {
  const { id } = useParams();
  const userId = id;
  const navigate = useNavigate();
  const passwordRef = useRef(null);

  const updatePassword = async () => {
    try {
      const response = await axios.post(`${API}/api/frontregi/changePassword`, {
        password: passwordRef.current.value,
        userId: userId,
      });

      console.log("Password updated successfully:", response.data);
      navigate("/login");
    } catch (error) {
      console.log("ALLERROR", error);
      alert(error.response.data.msg);

      console.error("Failed to update password:", error);
    }
  };

  return (
    <div
      className="position-relative  "
      style={{
        background: "linear-gradient(to right, white, #dbe1e3db)",
        height: "50vh",
      }}
    >
      <div className="d-flex justify-content-center align-items-center p-5">
        <form>
          <div>
            <h5>Enter New Password</h5>
          </div>
          <div>
            <input
              ref={passwordRef}
              type="password"
              placeholder="password"
              className="form-control"
            />
          </div>
          <div style={{ textAlign: "center" }}>
            <button
              type="button"
              className="btn btn-primary mt-2"
              onClick={updatePassword}
            >
              Submit Password
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Reset;
