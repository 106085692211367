import React from "react";
import "../../style/partials/components/_load.scss";

const Load = () => {
  return (
    <div
      className="position-fixed d-flex justify-content-center align-items-center loader-container"
      style={{ height: "140vh" }}
    >
      <p className="loaders"></p>
    </div>
  );
};

export default Load;
