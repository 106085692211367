import React, { useEffect, createContext, useState } from "react";
import axios from "axios";
import { API } from "../api-config";

const CategoriesContext = createContext();

export const Categoriesapi = ({ children }) => {
  const [allCategories, setAllCategories] = useState([]);
  const fetchCategories = async () => {
    const response = await axios.get(`${API}/api/categories/allCat`);

    setAllCategories(response.data);
  };
  useEffect(() => {
    fetchCategories();
  }, []);
  return (
    <CategoriesContext.Provider value={allCategories}>
      {children}
    </CategoriesContext.Provider>
  );
};

export default CategoriesContext;
