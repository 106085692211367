import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useForm } from "react-hook-form";
import { API } from "../../api-config";
import Load from "../Loading/Load";

const Resetting = (props) => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [load, setLoad] = useState(false);
  const onSubmit = async (data) => {
    try {
      setLoad((prevState) => !prevState);
      const response = await axios.post(`${API}/api/frontregi/forgotPassword`, {
        email: data.email,
      });
      console.log(response);
      alert(response.data.msg);
      navigate("/login");
      props.onSet(false);
    } catch (error) {
      console.error("Failed to reset password:", error);
    }
  };

  return (
    <div
      style={{
        position: "relative",
        background: "linear-gradient(to right, white, #dbe1e3db)",
        width: "100%",
        height: "50vh",
      }}
      className="d-flex align-items-center justify-content-center"
    >
      {load && <Load />}
      <div className="m-5 w-50">
        <h2 className="mb-2 font-weight-normal">Enter your email address</h2>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="w-100">
            <input
              type="email"
              placeholder="Enter Email"
              {...register("email", {
                required: "Email is required",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "Invalid email address",
                },
              })}
              className={`form-control ${
                errors.email ? "border border-danger" : "border-dark border-1"
              }`}
            />
            {errors.email && (
              <div className="text-danger">{errors.email.message}</div>
            )}
          </div>
          <div className="position-relative">
            <button className="btn reg-btn" type="submit">
              Reset Password
            </button>
            <button
              className="m-2 btn border-1 border-dark border-primary"
              onClick={() => {
                navigate("/login");
              }}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default React.memo(Resetting);
