import React, { useEffect, useContext } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { API } from "../../../api-config";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import "../../../style/partials/Counsellor/_conformation.scss";
import Loader from "../../Loader/Loader";
import UserContext from "../../../contexts/UserContext";
import { useLocation } from "react-router-dom";
import useRazorpay from "react-razorpay";
import socket from "../../../socket/socketio";
import { Modal } from "react-bootstrap";
import { forEach, head } from "lodash";
import alertify from "alertifyjs";
import SectionsHead from "../../Home/Heading";
import { throttle } from "lodash";
import Button from "../../Elements/Button";

let waitAccept = false;
let waitReject = false;
let sufficient = "";
let amountToBeAdded = 0;
let selectedSkill = [];
let waitAdd = false;
function Conformation() {
  const navigate = useNavigate();
  const [Razorpay] = useRazorpay();
  const [biddata, Setbiddata] = useState([]);
  const {
    register,
    formState: { errors },
    trigger,
    setValue,
    handleSubmit,
    clearErrors,
  } = useForm({
    mode: "onChange",
  });
  const [rejecteRequested, setRejectRequested] = useState(false);
  const [user, Setuser] = useState([]);
  const [scheduled, setScheduled] = useState([]);
  const [skill, Setskill] = useState([]);
  const [selected, SetSelected] = useState([]);
  const { userData } = useContext(UserContext);
  const [show, setShow] = useState(false);
  const [isPaymentDone, setPayment] = useState(false);
  const [sessionSkills, setSessionSkills] = useState(null);
  const [Total, setTotal] = useState(0);
  const [inputList, SetInputList] = useState([
    { sessions: `Slot ${1}`, Price: 0, description: "" },
  ]);
  const [sessionSlots, setSessionSlots] = useState(1);
  const [sessionImage, setSessionImage] = useState(null);
  let pricePattern = /[0]*/;

  const emp = useLocation();
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    SetInputList(list);
  };

  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    SetInputList(list);
    setSessionSlots((prevState) => prevState - 1);
    let test = Total - inputList[index].Price;
    setTotal((prevState) => prevState - inputList[index].Price);
    setTotal((prevState) => {
      return prevState;
    });
  };

  const handleAddClick = (i) => {
    const errorRes = formValidation(inputList);
    if (errorRes) {
      SetInputList([...inputList, { sessions: `Slot ${i + 2}`, Price: "" }]);
    } else {
    }
    setSessionSlots((prevState) => prevState + 1);
  };
  const updateUsers = async () => {
    await axios
      .post(`${API}/api/bidrequest/counsellor-bid-request`, {
        sessionid: emp.state,
      })
      .then((res) => {
        Setbiddata(res.data);
      });
  };
  useEffect(() => {
    const getNonScheduled = async () => {
      const scheduleData = await axios.get(
        `${API}/api/bidrequest/nonscheduled`,
        { headers: { userId: userData.userId, session: emp.state } }
      );
      setScheduled(scheduleData.data);
    };
    getNonScheduled();
    socket.on(`requestProp${userData.userId}`, () => {
      try {
        updateUsers();
      } catch (error) {
        console.log("ERRORS IN SOCKET", error);
      }
    });
    updateUsers();
    return () => {
      socket.off(`requestProp${userData.userId}`);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);
  const consid = userData.userId;
  // console.log("test", biddata.id)
  useEffect(() => {
    try {
      const updateData = async () => {
        await axios
          .get(`${API}/api/session/get-session/${emp.state}`, {
            headers: { token: userData.token },
          })
          .then((res) => {
            Setuser(res.data);
            setSessionSkills((prevState) => res.data.skills);
          });

        let myData = await axios.post(
          `${API}/api/counselor-profile/get-counselor-skill`,
          {
            userId: userData.userId,
          }
        );
        Setskill(myData.data.skills);
      };
      updateData();
    } catch (error) {
      console.log("error in getting session", error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (user.length === 0 || isPaymentDone) {
    return (
      <div>
        <Loader />
      </div>
    );
  }
  const formValidation = (inputList) => {
    try {
      const data = [...inputList];
      let validSess = true;
      let validPrice = true;
      let validDesc = true;
      for (let index = 0; index < data.length; index++) {
        if (
          data[index].Price === "" ||
          data[index].Price < 500 ||
          pricePattern.test(data[index].Price)
        ) {
          data[index].PriceCheck = "Enter valid price";
          validPrice = false;
        } else {
          data[index].PriceCheck = "";
          validPrice = true;
        }
        if (data[index].Price < 500) {
          data[index].PriceCheck = "Price should be above 499";
          validPrice = false;
        } else {
          data[index].PriceCheck = "";
          validPrice = true;
        }
        if (data[index].description == "") {
          data[index].sessionsCheck = " Description required";
          validDesc = false;
        }
      }
      SetInputList(data);
      return validSess && validPrice && validDesc;
    } catch (error) {
      console.log(error);
    }
  };

  const sessionUpdate = (e) => {
    const errorRes = formValidation(inputList);
    if (errorRes) {

      let Tot = 0;
      inputList.map((item, index) => {
        Tot += Number(item.Price);
      });
      try {
        axios
          .post(
            `${API}/api/session/update-session`,
            {
              sessionId: user._id,
              counsellorId: userData.userId,
              sessiontitle: e.sessiontitle,
              skills: sessionSkills,
              sessionNumber: inputList,
              sessionDes: e.sessiondes,
              sessionCost: Tot,
              defaultCurrency: userData.defaultCurrency,
              slotCount: sessionSlots,
              image: sessionImage,
            },
            {
              headers: {
                token: userData.token,
              },
            }
          )
          .then((res) => {
            socket.emit("newSession");
            navigate("/counsellor");
          });
      } catch (err) {
        console.log(err);
        alertify.error(err.response.data.status);
      }
    } else {
      alertify.error("Enter complete details");
    }
  };

  const checkBalance = async (bidId) => {
    const resd = await axios.get(`${API}/api/wallet/checkBalance`, {
      headers: {
        sessionId: user._id,
        entity: "counsellor",
        userId: userData.userId,
        token: userData.token,
        bidId: bidId,
      },
    });
    sufficient = resd.data.sufficient;
    amountToBeAdded = resd.data.amountToBeAdded;
    return resd.data.sufficient;
  };

  const scheduleSession = (id, bidAmount, userid, item) => {
    let info = {
      bidId: id,
      counsellorId: item.counselorid._id,
      userId: userid._id,
      sessionId: user._id,
      sessiontitle: user.sessiontitle,
      skills: user.skills,
      sessionDes: user.sessionDes,
      noOfSessions: item.sessionNumber,
      sessionDetails: item.sessionSlots,
    };

    navigate(`/event-create`, {
      state: {
        state: info,
        sessions: user.sessionNumber,
        leftBal: item.bidAmount,
      },
    });
  };

  const Accept = async (id, bidAmount, userid, item) => {
    try {
      let message = `${item.counselorid.fName} ${item.counselorid.lName} Accepted your counselling request on ${item.sessionid.sessiontitle}`;

      let notificationDetails = {
        msg: message,
        entity: "Session",
      };
      let ids = { bidAmount: amountToBeAdded, bidId: id };
      let paymentDecision = await checkBalance(id);
      if (paymentDecision) {
        navigate("/session-pay", {
          state: {
            session: user,
            _id: userid._id,
            msg: message,
            defaultCurrency: user.defaultCurrency,
            details: notificationDetails,
            userData: userid,
            data: emp,
            entity: "counsellor",
            bidId: id,
            // counsellorId: response.data.counselorId,
            sessiontitle: user.sessiontitle,
          },
        });
      } else {
        let paymentDetails = await axios.get(
          `${API}/api/wallet/pay-counselling`,
          {
            params: {
              id: ids,
            },
            headers: {
              userId: userData.userId,
              entity: "counsellor",
              sessionId: emp.state,
              token: userData.token,
              bidId: id,
            },
          }
        );
        var options = {
          key: process.env.RAZORPAY_KEY_ID,
          order_id: paymentDetails.data.order.id,
          amount: Math.ceil(amountToBeAdded),
          // currency: `${userData.defaultCurrency}`,
          currency: `INR`,
          name: "Ooze IT Solutions Pvt Ltd",
          handler: async function (paymentDetails) {
            try {
              debugger;
              setPayment((prevState) => !prevState);
              const status = "SUCCESS";
              let details = {
                ...paymentDetails,
                ...ids,
                status,
                consid,
                _id: user._id,
              };

              const res = await axios.put(
                `${API}/api/bidrequest/confirm-bidrequest/${id}`,
                {
                  headers: {
                    token: userData.token,
                  },
                },
                {
                  headers: {
                    token: userData.token,
                  },
                }
              );

              let info = {
                bidId: res.data.id,
                counsellorId: item.counselorid._id,
                userId: userid._id,
                sessionId: user._id,
                sessiontitle: user.sessiontitle,
                skills: user.skills,
                sessionDes: user.sessionDes,
                noOfSessions: item.sessionNumber,
                sessionDetails: item.sessionSlots,
              };

              const addTransaction = await axios.post(
                `${API}/api/transaction/insert-transaction`,
                {
                  data: {
                    ...details,
                    bidReqId: id,
                    userId: { _id: item.counselorid._id },
                    amount: amountToBeAdded,
                    bidAmount: amountToBeAdded,
                    sessionId: user._id,
                    counselorid: { _id: item.counselorid._id },
                    currency: user.defaultCurrency,
                    transactionType: "CREDIT",
                    transactionVia: "Razorpay",
                  },
                },
                { headers: { token: userData.token } }
              );
              //amount add hoga as per currency

              const addMoneyToWallet = await axios.put(
                `${API}/api/wallet/add-amounts/${userData.userId}`,
                {
                  bidId: id._id,
                  amount: amountToBeAdded,
                  userId: userData.userId,
                  sessionId: emp.state,
                },
                {
                  headers: {
                    token: userData.token,
                  },
                }
              );

              const resWal = await axios.post(
                `${API}/api/wallet/paid-via-wallet`,
                null,
                {
                  headers: {
                    userId: userData.userId,
                    bidId: id,
                    sessionId: emp.state,
                    bidCost: Number(0.03 * item.bidAmount),
                    sessionCurrency: user.defaultCurrency,
                    entity: "counsellor",
                    token: userData.token,
                  },
                }
              );

              const notificationResponse = await axios.post(
                `${API}/api/notifications/update`,
                {
                  details: notificationDetails,
                  userData: userid,
                },
                { headers: { token: userData.token } }
              );

              socket.emit("noti", {
                userId: userid._id,
                bidId: id,
                item: item,
                _id: userid._id,
                msg: message,
              });
              socket.emit("sessionDecision", {
                userId: userid._id,
                bidId: id,
                item: item,
                _id: userid._id,
                msg: message,
                status: "ACCEPTED",
              });
              navigate(`/event-create`, {
                state: {
                  state: info,
                  sessions: user.sessionNumber,
                  leftBal: item.bidAmount,
                },
              });
              setPayment(false);
              alert("Payment Successful");
              // navigate("/students");
            } catch (error) {
              console.log(error);
            }
          },
        };
        const rzp1 = new Razorpay(options);
        rzp1.open();
        rzp1.on("payment.failed", async (response) => {
          const status = "FAILED";
          let details = { ...response, ...id, status };
          const addTransaction = await axios.post(
            `${API}/api/transaction/insert-transaction`,
            { data: details }
          );
          alert(response.error.code);
        });
      }
    } catch (err) {
      console.log(err, "Accept");
    }
  };

  const Reject = async (id, bidAmount, userid, item, currency) => {
    let message = `${userData.fName} ${userData.lName} Rejected your counselling request on ${item.sessionid.sessiontitle}`;
    let notificationDetails = {
      msg: message,
      entity: "Session",
    };

    let messageWallet = `A refund for your booking session (${item.sessionid.sessiontitle}) has been credited to your wallet.`;
    let notificationWalletDetails = {
      msg: messageWallet,
      entity: "Wallet",
    };

    try {
      axios
        .put(`${API}/api/bidrequest/reject-bidrequest/${id}`, null, {
          headers: {
            token: userData.token,
          },
        })
        .then((res) => {
          Setbiddata((prevState) => prevState.filter((item) => item._id != id));
        });

        const walletUpdated = await axios.post(
          `${API}/api/wallet/refund-amount-wallet`,
          { userId: userid._id, amount: bidAmount, currency: currency, sessionId:item.sessionid._id },
          { headers: { token: userData.token } }
        );

      const notificationResponse = await axios.post(
        `${API}/api/notifications/update`,
        { details: notificationDetails, userData: userid },
        {
          headers: {
            token: userData.token,
          },
        }
      );


      const notificationWalletResponse = await axios.post(
        `${API}/api/notifications/update`,
        { details: notificationWalletDetails, userData: userid },
        {
          headers: {
            token: userData.token,
          },
        }
      );

      socket.emit("noti", {
        userId: userid,
        bidId: id,
        item: item,
        _id: userid._id,
        msg: message,
      });
      socket.emit("sessionDecision", {
        userId: userid,
        bidId: id,
        item: item,
        _id: userid._id,
        msg: message,
        status: "REJECTED",
      });
    } catch (err) {
      console.log(err, "Raject");
    }
  };

  const EditSession = () => {
    setShow(true);
    setValue("sessiontitle", user.sessiontitle);
    setValue("sessiondes", user.sessionDes);
    SetInputList(user.sessionNumber);
    setSessionImage(user.image);
  };

  const filterSessionSkills = (item) => {

    setSessionSkills((prevState) =>
      prevState.filter((eitem) => item._id != eitem._id)
    );
  };

  const addSessionSkills = (item) => {
    if (!sessionSkills.some((prod) => prod._id == item._id)) {
      setSessionSkills((prevState) => [...sessionSkills, item]);
    }
  };


  const handleSessionImage = (event) => {
    const fileInput = event.target;
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append('image', file);

    try {
      axios
        .post(`${API}/api/session/upload-image`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data', // Set the content type
            token: userData.token,
          },
        })
        .then((res) => {
          setSessionImage(res.data.url);
          fileInput.value = '';
        });
    } catch (error) {
      console.error("Error uploading session:", error);
    }

  };


  return (
    <section className="request-confirm-div container pt-5 pb-5">
      <SectionsHead heading="Session INFO" />

      <div className="container shadow rounded">
        <div className="row p-3">
          <div className="col-md-12 d-sm-flex justify-content-between p-3">
            <div>
              <label>Session Name :</label>
              <p className="h3 primary-text"><b>{user.sessiontitle}</b></p>
            </div>

            <div>
              <label>Created Date :</label>
              <p className="h4 primary-text"><b>{new Date(user.createdAt).toDateString().slice(4, 15)}</b></p>
            </div>
          </div>

          {user.sessionNumber.length > 0 &&
            user.sessionNumber?.map((item, index) => {
              return (
                <div key={`${item}${index}`} className="col-sm-3">
                  <div className="d-flex flex-column text-center p-3 shadow rounded">
                    <span>Session No. :</span>
                    <label className="primary-text h5"><b>{item?.sessions}</b></label>

                    <span>Session Price :</span>
                    <label className="primary-text h5"><b>{`${item?.Price} ${user.defaultCurrency}`}</b></label>
                  </div>
                </div>
                );
          } ) }

          <div className="col-md-12 px-3 py-3 mt-3">
            <label>Skills :</label>
            <div className="mt-2 text-break">
              {sessionSkills && sessionSkills
                ? sessionSkills.map((item, index2) => {
                    return <label className="p-2 rounded text-white primary-bg mr-2 mb-2" key={`${item}${index2}`} id="result{`${item}${index2}`}">{item?.skill}</label>;
                  })
                : null}
            </div>
          </div>

          <div className="col-md-12 d-sm-flex px-3 py-1">
            <div>
              <label>Description :</label>
              <p><b>{user?.sessionDes?.charAt(0).toUpperCase() + user?.sessionDes?.slice(1)}</b></p>
            </div>
          </div>

          <div className="col-md-12 mb-3">
            <button onClick={() => EditSession()} className="col-2 edit-btn primary-bg text-white p-2 rounded border-0">Edit</button>
          </div>
        </div>
      </div>

      {biddata.length > 0 && (
        <div className="container mt-5 mb-5 p-0">
          <div className="row">
          {biddata.length > 0 &&
            biddata?.map((item, index) => {
              return (
                <div className="col-lg-3 col-md-6" key={`${item}${index}`}>
                  <div className="d-flex flex-column text-center p-3 shadow rounded">
                    <span>Booking By :</span>
                    <label className="primary-text h5"><b>{item.userId.fName.charAt(0).toUpperCase() +
                            item.userId.fName.slice(1)}{" "}{item.userId.lName.charAt(0).toUpperCase() +
                            item.userId.lName.slice(1)}{" "}</b></label>

                    <div className="row">                
                      <div className="col-6 d-flex flex-column text-center">
                        <span>Session No. :</span>
                        <label className="primary-text h5"><b>{item.sessionNumber}</b></label>
                      </div>
                      <div className="col-6 d-flex flex-column text-center">
                        <span>Session Price :</span>
                        <label className="primary-text h5"><b>{`${item.bidAmount} ${user.defaultCurrency}`}</b></label>
                      </div>
                    </div>

                    <div className="row mt-3">  
                      {scheduled.includes(item._id) && (
                        <div className="col-sm-12 d-flex flex-column text-center mb-sm-0 mb-3">
                          <button
                            className="edit-btn primary-bg text-white p-2 rounded border-0"
                            onClick={() => {
                              scheduleSession(
                                item._id,
                                item.bidAmount,
                                item.userId,
                                item
                              );
                            }}
                          >
                            Schedule
                          </button>
                        </div>
                      )}
                      {item.conformation === true ? (
                        <div className="col-sm-12 d-flex flex-column text-center mb-sm-0 mb-3">
                          {" "}
                          <button
                            type="button"
                            id=""
                            className="accept-btn edit-btn primary-bg text-white p-2 rounded border-0"
                          >
                            ACCEPTED
                          </button>
                        </div>
                      ) : (
                          <>
                            <div className="col-sm-6 d-flex flex-column text-center mb-sm-0 mb-2">
                              {" "}
                              <button type="button" id="" className="accept-btn edit-btn primary-bg text-white p-2 rounded border-0"
                                onClick={() => {
                                  if (waitAccept) {
                                    return;
                                  }
                                  waitAccept = true;
                                  setTimeout(() => {
                                    waitAccept = false;
                                  }, 4000);
                                  Accept(
                                    item._id,
                                    item.bidAmount,
                                    item.userId,
                                    item
                                  );
                                }}
                              >
                                ACCEPT
                              </button>
                            </div>
                            <div className="col-sm-6 d-flex flex-column text-center">
                              <button
                                type="button"
                                className="reject-btn edit-btn bg-dark text-white p-2 rounded border-0"
                                onClick={() => {
                                  if (waitReject) {
                                    return;
                                  }
                                  setRejectRequested((prevState) => true);
                                  waitReject = true;
                                  setTimeout(() => {
                                    waitReject = false;
                                    setRejectRequested((prevState) => false);
                                  }, 4000);
                                  Reject(
                                    item._id,
                                    item.bidAmount,
                                    item.userId,
                                    item,
                                    user.defaultCurrency
                                  );
                                }}
                              >
                                REJECT
                              </button>
                            </div>
                          </> 
                      )}
                    </div>
                  </div>
                </div>
                );
            })}
          </div>
        </div>
      )}

      <Modal show={show} className="pl-0">
        <Modal.Header
          closeButton
          onClick={() => {
            setShow(false);
          }}
        />
        <SectionsHead heading="Session Detail" />
        <Modal.Body style={{overflow: "scroll"}}>
          <form
            onSubmit={handleSubmit((data) => {
              if (waitAdd) {
                return;
              }
              waitAdd = true;
              setTimeout(() => {
                waitAdd = false;
              }, 4000);
              sessionUpdate(data);
            })}
          >
            <div className="d-flex flex-column">
              <label className="session-label mt-2">
                Write a title of your session?
              </label>
              <input
                className="mt-2"
                type="text"
                onKeyPress={(e) => {
                  if (e.target.value === "" && e.key === " ") {
                    e.preventDefault();
                  }
                }}
                {...register("sessiontitle", { required: true })}
                id="ses-title"
              />
              {errors.sessiontitle && (
                <p className="valid-prof">This field is Required</p>
              )}
            </div>

            <div className="">
              <label className="session-label mt-2">
                Write Description of your session?
              </label>
              <textarea
                {...register("sessiondes", { required: true })}
                type="text"
                onKeyPress={(e) => {
                  if (e.target.value === "" && e.key === " ") {
                    e.preventDefault();
                  }
                }}
                className="mt-2"
                id="ses-des"
              />
              {errors.sessiondes && (
                <p className="valid-prof">This field is Required</p>
              )}
            </div>

            <div className="row">
              <div className="col-md-6 check-skill">
                  <h6 className="session-label mt-2">Your Skills</h6>
                  {skill &&
                    skill.map((item, index) => (
                      <label
                        key={`${item}${index}`}
                        onClick={() => {
                          addSessionSkills(item);
                        }}
                        className="cust-skill"
                      >
                        {item.skill}
                      </label>
                    ))}
                  {errors?.skills?.type === "required" && (
                    <p className="valid-prof">Please Select Session</p>
                  )}
                  {/* <p>vale:{selected}</p> */}
              </div>

              <div className="col-md-6 check-skill">
                <h6 className="session-label mt-2">Selected skills for the session</h6>
                {sessionSkills && sessionSkills.length > 0
                ? sessionSkills.map((item, index3) => (
                    <label key={`${item}${index3}`}  id="result">
                      {item?.skill}
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          filterSessionSkills(item);
                        }}
                      >
                        X
                      </button>
                    </label>
                  ))
                : null}
              </div>
            </div>     

            <div className="row mb-2">
              <div className="col-md-6">
                <label className="session-label mt-2 mb-2">
                  Session Image
                </label>
                <input
                  id="sessionImage"
                  type="file"
                  accept=".png, .jpg, .jpeg"
                  onChange={handleSessionImage}
                />
              </div>
              <div className="col-md-6">
                {sessionImage ? (
                  <div className="p-3 pl-0">
                  <img
                    src={sessionImage}
                    className="session-img"
                    alt="session-img"
                    loading="lazy"
                    style={{ cursor: "pointer" }}
                    height="200"
                    width="200"
                  />
                </div>
                ) : null}
              </div>
            </div>

            <div className=" det-button">
              <h6 className="session-label mt-2 text-start-mdfy">
                Estimate the Number and Price of your session
                <span style={{ color: "red" }}>*</span>
              </h6>
              {inputList.map((x, i) => {
                return (
                  <div className="bid-list row" key={`${x.id}${i}`}>
                    <div className="valid-sess col-lg-3">
                      <input
                        type="number"
                        name="sessions"
                        className="bid-item"
                        placeholder={`Slot ${i + 1}`}
                        value={`Slot ${i}`}
                        readOnly
                        onChange={(e) => handleInputChange(e, i)}
                      />
                    </div>
                    <div className="valid-sess col-lg-3">
                      <input
                        type="number"
                        name="Price"
                        className="bid-item"
                        placeholder="Sessions Price"
                        onKeyDown={(e) => {
                          if (
                            e.keyCode == 38 ||
                            e.keyCode == 37 ||
                            e.keyCode == 39 ||
                            e.keyCode == 40
                          ) {
                            e.preventDefault();
                          }
                        }}
                        onWheel={(e) => {
                          e.preventDefault();
                        }}
                        value={x.Price}
                        onChange={(e) => handleInputChange(e, i)}
                      />
                      
                    </div>
                    <div className="valid-desc col-lg-4">
                      <input
                        type="text"
                        name="description"
                        className="bid-item"
                        placeholder="Session Detail"
                        value={x.description}
                        onChange={(e) => handleInputChange(e, i)}
                      />
                      <p>{x.sessionsCheck}</p>
                    </div>
                    <div className="col-lg-2 d-flex add-btn">
                      {inputList.length - 1 === i && (
                        <button
                          type="button"
                          className="ad-sub-btn cust-btn"
                          onClick={() => handleAddClick(i)}
                        >
                          +
                        </button>
                      )}
                      {inputList.length > 1 && (
                        <button
                          type="button"
                          className="ad-sub-btn"
                          style={{ background: "red" }}
                          onClick={() => handleRemoveClick(i)}
                        >
                          X
                        </button>
                      )}
                    </div>
                  </div>
                );
              })}
              <Button
                type="submit"
                className="btn btn-outline-primary custom-btn btn-8 next-btn4 "
                context="Submit"
              />
            </div>
          </form>
        </Modal.Body>
      </Modal>
        {isPaymentDone ||
          (rejecteRequested && (
            <Modal show={isPaymentDone || rejecteRequested}>
              <Loader />
            </Modal>
        ))}
    </section>
  );
}

export default React.memo(Conformation);