import React from "react";
import axios from "axios";

const CurrencyConverter = () => {
  const tolAmount = async (cost, from, to) => {
    try {
      const response = await axios.get(
        "https://api.freecurrencyapi.com/v1/latest?apikey=fca_live_fEt0c5zLpUJY56Orw74x65CnEikODFllCT8mcAK2"
      );
      console.log(
        response,
        "cost",
        cost,
        "response.data.data.from",
        response.data.data[from],
        "response.data.data.to",
        response.data.data[to]
      );
      const requiredDefaultCurrency = Number(
        cost * response.data.data[from] * response.data.data[to]
      ).toFixed(2);
      console.log("requiredDefaultCurrency", requiredDefaultCurrency);
      return requiredDefaultCurrency;
    } catch (error) {
      console.error(error);
    }
  };

  return { tolAmount };
};

export default CurrencyConverter;
