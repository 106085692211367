import React, { useContext, useEffect, useState } from "react";
import SectionsHead from "../../Home/Heading";
import profile from "../../../images/test.png";
import axios from "axios";
import Loader from "../../Loader/Loader";
import UserContext from "../../../contexts/UserContext";
import "../../../style/partials/components/profile.scss";
import { API } from "../../../api-config";
import { useNavigate } from "react-router-dom";
import Counsellorwallet from "../Wallet/Counsellorwallet";
import AboutSession from "../Home/AboutSession";
import { useLocation } from "react-router-dom";
function CounsellorProfile() {
  const Navigate = useNavigate();
  const { userData } = useContext(UserContext);
  //const [photo123, setphoto123] = useState("");
  //const [name, setname] = useState("");
  const [data, setData] = useState([]);
  const [show, setShow] = useState(true);
  const [successnotification, setSuccessNotification] = useState("");
  const location = useLocation();

  useEffect(() => {
    // Check if state was passed from the previous component
    if (location.state) {
      // Update the state based on the passed value
      setShow(location.state.show);
      setSuccessNotification(location.state.successnotification)
    }
  }, [location.state]);
  useEffect(() => {
    if (!userData.isProfile) {
      Navigate("/counsellor");
    }
    const updateUsers = async () => {
      try {
        let id = userData.userId;
        let myData = await axios.get(
          `${API}/api/counselor-profile/getCounselor`,
          {
            params: { id },
            headers: {
              token: userData.token,
            },
          }
        );
        setData(myData.data);
      } catch (error) {
        console.log(error);
      }
    };
    updateUsers();
  }, [userData.userId]);
  if (data.length === 0) {
    return (
      <div>
        <Loader />
      </div>
    );
  }
  
  function Edit(id) {
    Navigate("/eit-profile", { state: id });
  }

  return (
    <>
    {successnotification && (
      <div className="container mb-3 mt-3">
        <div className="row">
          <div className="alert alert-success alert-dismissible">
            {" "}
            <a
              onClick={() => {
                setSuccessNotification("");
              }}
              className="close"
              data-dismiss="alert"
              aria-label="close"
            >
              &times;
            </a>
            {successnotification}
          </div>
        </div>
      </div>
    )}
   
    <section className="container mt-5 mb-5">
      {data &&
        data.map((x, index) => {
          console.log("XXXXXXXX", x);
          return (
            <div
              className="container profile-cart p-3 col-lg-12  shadow-sm mt-4"
              key={index}
            >
              <div className="row">
                <div className="col-12 col-md-12 id-card">
                  <div className="col-12 col-md-6 id-card-det">
                    <div className="col-4 col-md-4 col-md-3">
                      <div className="profile-cart-img text-center">
                        <img
                          src={x?.profilePic ? x.profilePic : profile}
                          alt="profile pic"
                          loading="lazys"
                          height="60"
                          width="60"
                        />
                      </div>
                    </div>

                    <div className="col-8 col-md-8 align-self-center">
                      <div className="profile-header-detail ">
                        <h6>
                          {x.userId?.fName.charAt(0).toUpperCase() +
                            x.userId.fName.slice(1) +
                            " " +
                            x.userId?.lName.charAt(0).toUpperCase() +
                            x.userId?.lName.slice(1)}
                        </h6>
                        <h6>{x.userId.email}</h6>
                        <h6>{x.userId.mobileNo}</h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 id-card-btns">
                    <div className="col-4 col-md-4 ">
                      <button
                        className="wallet-btn"
                        type="button"
                        onClick={() => setShow(!show)}
                      >
                        Wallet
                      </button>
                    </div>
                    <div className="col-4 col-md-4">
                      <button
                        className="edit-btn"
                        type="button"
                        onClick={() => Edit(x._id)}
                      >
                        Edit Profile
                      </button>
                    </div>
                    <div className="col-4 col-md-4 ">
                      <button
                        className="wallet-btn"
                        type="button"
                        onClick={() => Navigate("/transactions")}
                      >
                        Transactions
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}

      {show ? null : <Counsellorwallet />}
      <AboutSession />
    </section>
    </>
  );
}
export default React.memo(CounsellorProfile);
