import { React, useState, useEffect, useContext } from "react";
import axios from "axios";
import { API } from "../../../api-config";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import UserContext from "../../../contexts/UserContext";
import photoe from "../../../images/bg2.png";
import "../../../style/partials/Counsellor/_counsellorprofile.scss";
import SectionsHead from "../../Home/Heading";

function EditProfile() {
  const { userData } = useContext(UserContext);
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    clearErrors,
  } = useForm();

  const Navigate = useNavigate();
  const emp = useLocation();
  const [Fname, setFname] = useState("");
  const [Lname, setLname] = useState("");
  const [degree, setdegree] = useState("");
  const [skills, setskills] = useState("");
  const [address, setaddress] = useState("");
  const [city, setcity] = useState("");
  const [state, setstate] = useState([]);
  const [country, setcountry] = useState([]);
  const [zip, setzip] = useState("");
  const [number, setnumber] = useState("");
  const [photo, setphoto] = useState("");
  const [selectcountry, setselectcountry] = useState("");
  const [selectstate, setselectstate] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [profileImage, setProfileImage] = useState(null);
  const [proofDoc, setProofDoc] = useState(null);
  const [user, setUser] = useState(null);

  //console.log(emp.state,"test")

  useEffect(() => {
    const updateUsers = async () => {
      let myData = await axios.get(
        `${API}/api/user-profile/get-profile/${emp.state}`,
        {
          headers: {
            token: userData.token,
          },
        }
      );
      setUser((prevState) => myData.data);
      const profilePic = myData.data.profilePic[0];
      console.log(myData.data);
      setValue("fname", myData.data.userId.fName);
      setValue("lname", myData.data.userId.lName);
      setValue("degree", myData.data.Education);
      setdegree(myData.data.Education);
      setValue("address", myData.data.address);
      setValue("city", myData.data.city);
      setValue("state", myData.data.state);
      setselectstate(myData.data.state);
      setValue("country", myData.data.country);
      setselectcountry(myData.data.country);

      let selectedCountryId = myData.data.country ? myData.data.country : null;
      if (selectedCountryId) {
        await axios.post(`${API}/api/state/get-statebycountryname`, {
          countryName: selectedCountryId,
        }).then((response) => {
          setstate(response.data);
        });

      }

      setValue("zipcode", myData.data.zip);
      setValue("phone1", myData.data.phone1);
      setphoto((prevState) => profilePic);
    };
    updateUsers();
  }, [emp.state]);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    const imageUrl = URL.createObjectURL(file);
    setProfileImage(imageUrl);
    setSelectedImage((prevState) => [file]);
  };
  const uploadChunk = async (
    file,
    chunk,
    chunkIndex,
    context,
    uploadId,
    partNumber,
    parts
  ) => {
    console.log(parts);
    const formData = new FormData();
    console.log("CHUNK", chunk);
    formData.append("file", chunk);
    formData.append("fileName", file.name);
    formData.append("chunkIndex", chunkIndex);
    formData.append("context", context);
    formData.append("uploadId", uploadId);
    formData.append("partNumber", partNumber);
    formData.append("parts", JSON.stringify(parts));
    console.log(formData);
    try {
      const response = await axios.post(
        `${API}/api/counselor-profile/uploadChunk`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            token: userData.token,
          },
        }
      );
      console.log(response.data);
      return response.data.parts;
    } catch (error) {
      console.error("Error uploading chunk:", error);
    }
  };
  const uploadFiles = async () => {
    if (!selectedImage && !proofDoc) {
      return;
    }
    let context =
      selectedImage != null && proofDoc != null
        ? ["profilePic", "proofdoc"]
        : selectedImage == null && proofDoc != null
        ? ["proofdoc"]
        : selectedImage != null && proofDoc == null
        ? ["profilePic"]
        : [];

    let files =
      selectedImage != null && proofDoc != null
        ? [selectedImage, proofDoc]
        : selectedImage == null && proofDoc != null
        ? [proofDoc]
        : selectedImage != null && proofDoc == null
        ? [selectedImage]
        : [];
    console.log(context, files);
    const formData = new FormData();
    const CHUNK_SIZE = 1024 * 1024;

    files.forEach(async (item, index) => {
      item.forEach(async (file) => {
        let keyName = `/${userData.userId}/${Date.now()}-${context[index]}/${
          file.name
        }`;
        console.log(index, item, file, keyName);
        const responseInitiation = await axios.post(
          `${API}/api/user-profile/initiatemultipart`,
          { keyName: keyName },
          {
            headers: {
              token: userData.token,
            },
          }
        );
        const uploadId = responseInitiation.data.uploadId;
        let parts = [];
        const totalChunks = Math.ceil(file.size / CHUNK_SIZE);
        console.log(totalChunks);
        for (let i = 0; i < totalChunks; i++) {
          const start = i * CHUNK_SIZE;
          const end = Math.min(start + CHUNK_SIZE, file.size);
          const chunk = file.slice(start, end);
          const response = await uploadChunk(
            file,
            chunk,
            i,
            keyName,
            uploadId,
            i + 1,
            parts
          );
          parts = response;
        }
        console.log("ONe FILE UPLOADED");
        await axios.post(
          `${API}/api/user-profile/upload-complete`,
          {
            keyName: keyName,
            uploadId: uploadId,
            parts: parts,
            fileName: file.name,
            context: context[index],
          },
          {
            headers: {
              token: userData.token,
            },
          }
        );
      });
    });
  };

  const submit = (e) => {
    console.log(e);
    const post = {
      fName: e.fname,
      lName: e.lname,
      Eduction: e.degree,
      address: e.address,
      city: e.city,
      state: e.state,
      country: e.country,
      zip: e.zipcode,
      phone1: e.phone1,
    };
    //console.log(company, "compaany");
    axios
      .put(`${API}/api/user-profile/profile-update/${emp.state}`, post)
      .then((res) => {
        console.log(res.data);
        uploadFiles();
        Navigate("/student-profile");
      });
    // // console.log("checl");
  };

  //country
  useEffect(() => {
    const getcountry = async () => {
      let myData = await fetch(`${API}/api/country/get-Country`);
      let coutrydata = await myData.json();
      setcountry(await coutrydata);
    };
    getcountry();
  }, []);

  const handlecountry = (value) => {
    let countryid = country.find((key) => key.countryName === value);
    console.log(countryid);
    setselectstate(() => "");
    let selectedCountryId = countryid ? countryid._id : null;
    if (selectedCountryId) {
      getstate(selectedCountryId);
    }
  };

  const getstate = async (getcountryid) => {
    let myData = await axios.post(`${API}/api/state/get-statebycountry`, {
      countryId: getcountryid,
    });
    setstate(myData.data);
  };

  const handlestate = (event) => {
    const getcountryid = event.target.value;
    console.log(getcountryid, "master");
    setselectstate(getcountryid);
  };

  const handleChangePortfolio = (e) => {
    setProofDoc((prevState) => [e.target.files[0]]);
  };

  return (
    <>
      <section className="p-3">
        <div className="profile-main-title text-center mt-1">
          <SectionsHead heading="Update Profile" />
        </div>
        <div className="container student-profile-div">
          <form
            className="profile-form  text-center"
            onSubmit={handleSubmit(submit)}
          >
            <div className="row main-profile-updt-div shadow-sm">
              <div className="col-md-6">
                <div className="shadow-sm update-image-box">
                  <label htmlFor="profile-img">
                    <input
                      id="profile-img"
                      type="file"
                      accept="image/*"
                      onChange={handleImageChange}
                      style={{ display: "none" }}
                    />
                    <img
                      src={profileImage ? profileImage : user?.profilePic}
                      className="profile-img"
                      alt="profile-img"
                      loading="lazy"
                      style={{ cursor: "pointer" }}
                      height="60"
                      width="60"
                    />
                  </label>
                </div>
                <input
                  // defaultValue={Fname}
                  className="input-field"
                  onKeyPress={(e) => {
                    if (e.target.value === "" && e.key === " ") {
                      e.preventDefault();
                    }
                  }}
                  {...register("fname", {
                    required: true,
                    validate: (value) => value.trim() !== "",
                    maxLength: 20,
                    pattern: /^[a-zA-Z\s]*[a-zA-Z][a-zA-Z\s]*$/,
                  })}
                />
                {errors?.fname?.type === "required" && (
                  <p>Please Enter FirstName</p>
                )}
                {errors?.fname?.type === "pattern" && (
                  <p>Alphabetical characters only</p>
                )}
                <input
                  className="input-field"
                  type="text"
                  // defaultValue={Lname}
                  onKeyPress={(e) => {
                    if (e.target.value === "" && e.key === " ") {
                      e.preventDefault();
                    }
                  }}
                  {...register("lname", {
                    required: true,
                    validate: (value) => value.trim() !== "",
                    maxLength: 20,
                    pattern: /^[a-zA-Z\s]*[a-zA-Z][a-zA-Z][a-zA-Z\s]*$/,
                  })}
                />
                {errors?.lname?.type === "required" && (
                  <p>Please Enter LastName</p>
                )}
                {errors?.lname?.type === "pattern" && (
                  <p>Alphabetical characters only</p>
                )}
                {degree && (
                  <select
                    className="custom-select"
                    id="selectmethod1"
                    name="company"
                    {...register("degree")}
                  >
                    <option defaultValue disabled>
                      {degree}
                    </option>
                    <option>10th</option>
                    <option>12th</option>
                    <option>Graduate</option>
                    <option>Post-Graduate </option>
                  </select>
                )}
                <input
                  className="input-field"
                  type="number"
                  // defaultValue={number}
                  placeholder="Alternate Contact Number"
                  onKeyDown={(e) => {
                    console.log(e.key, " ,", e.keyCode);
                    if (
                      e.keyCode == 38 ||
                      e.keyCode == 37 ||
                      e.keyCode == 39 ||
                      e.keyCode == 40
                    ) {
                      e.preventDefault();
                    }
                  }}
                  onWheel={(e) => {
                    e.preventDefault();
                  }}
                  {...register("phone1", {
                    required: true,
                    validate: (value) => {
                      if (
                        value !== "" &&
                        value.length <= 13 &&
                        value.length >= 10
                      ) {
                        return true;
                      }
                    },
                  })}
                  name="phone1"
                />
                {errors?.phone1?.type === "required" && (
                  <p>Enter the mobile number</p>
                )}
                {errors?.phone1?.type === "validate" && (
                  <p>Min 10 digit allowed max 13 digit with country code</p>
                )}
              </div>
              <div className="col-md-6">
                <textarea
                  className="input-field"
                  type="text"
                  // defaultValue={address}
                  onKeyPress={(e) => {
                    if (e.target.value === "" && e.key === " ") {
                      e.preventDefault();
                    }
                  }}
                  {...register("address", {
                    required: true,
                    validate: (value) => value.trim() !== "",
                  })}
                  placeholder="Address"
                />
                {errors?.address && <p>Please enter address</p>}

                {selectcountry && (
                  <select
                    {...register("country")}
                    onChange={(e) => handlecountry(e.target.value)}
                    // defaultValue={selectcountry}
                  >
                    <option defaultValue hidden>
                      {selectcountry}
                    </option>
                    {country.map((getcon, index) => {
                      return (
                        <option key={getcon._id}>{getcon.countryName}</option>
                      );
                    })}
                  </select>
                )}
                {(selectstate || selectcountry) && (
                  <select {...register("state")} defaultValue={selectstate}>
                    <option defaultValue hidden>
                      {selectstate}
                    </option>
                    {state.map((getste, index) => {
                      return (
                        <option key={index} value={getste.stateName}>
                          {getste.stateName}
                        </option>
                      );
                    })}
                  </select>
                )}
                <input
                  type="text"
                  className="input-field"
                  {...register("city", {
                    required: true,
                    validate: (value) => value.trim() !== "",
                  })}
                  defaultValue={city}
                />
                {errors.city && <p>This Field is Required</p>}
                <input
                  className="input-field"
                  type="number"
                  placeholder="Zip Code"
                  onKeyDown={(e) => {
                    console.log(e.key, " ,", e.keyCode);
                    if (
                      e.keyCode == 38 ||
                      e.keyCode == 37 ||
                      e.keyCode == 39 ||
                      e.keyCode == 40
                    ) {
                      e.preventDefault();
                    }
                  }}
                  onWheel={(e) => {
                    e.preventDefault();
                  }}
                  // defaultValue={zip}
                  {...register("zipcode", {
                    required: true,
                    maxLength: 8,
                    validate: (value) => {
                      if (value !== "" && value.length <= 8) {
                        return true;
                      }
                    },
                  })}
                />
                {errors?.zipcode?.type === "required" && (
                  <p>Enter the zipcode</p>
                )}
                {errors?.zipcode?.type === "validate" && (
                  <p>Max 8 digit allowed</p>
                )}
                {errors?.zipcode?.type === "maxLength" && (
                  <p>Max 8 digit allowed</p>
                )}
              </div>
              {user?.proofdoc ? (
                <div>
                  <ul className="list-style-none">
                    <li>
                      <a
                        href={user?.proofdoc[0]} // Note the lowercase 'proofdoc' here
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Download File
                      </a>
                    </li>
                    <label htmlFor="portfo">
                      Update document
                      <input
                        id="portfo"
                        type="file"
                        accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                        onChange={handleChangePortfolio}
                      />
                    </label>
                  </ul>
                </div>
              ) : (
                <div>
                  <label htmlFor="portfo">
                    Update document
                    <input
                      id="portfo"
                      type="file"
                      accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                      onChange={handleChangePortfolio}
                    />
                  </label>
                </div>
              )}

              <div className="mt-3 mb-3">
                <button type="submit" className="update-profile">
                  Update Profile
                </button>
              </div>

              {/* <div className="col-md-6">
            <form className="profile-form  text-center" onSubmit={handleSubmit(submit)}>

            

            </form>
            </div>
            <div className="col-md-6">
            <form className="profile-form  text-center" onSubmit={handleSubmit(submit)}>



 
            </form>
            </div> */}

              {/*  */}
              {/* <div className=" col-md-6  col-12  image-box-prfile">
              <div className="blur-bg-profile"></div>
              <div className="img-contanent-btn">
              <img
                src={photoe}
                className="profile-img"
                alt="profile-img"
              />
            
              </div>
            </div> */}
              {/* 
            <div className="form-div col-md-6">
            <form className="profile-form  text-center" onSubmit={handleSubmit(submit)}>
          <div className="profile-input"> */}

              {/*            
            <button type="submit" className="profile-sub-btn">
              UpdateProfile
            </button> */}
              {/* </div>
       
              </div> */}
            </div>
          </form>
        </div>
      </section>
    </>
  );
}
export default EditProfile;
