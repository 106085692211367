

import UserContext from "../../../contexts/UserContext";
import React, { useState, useContext, useEffect } from "react";
import Walletadd from "../../../data/Walletamount";
import axios from "axios";
import { API } from "../../../api-config";
import { useNavigate } from "react-router-dom";
import useRazorpay from "react-razorpay";
import { useLocation } from "react-router-dom";
function Walletscreen() {
  const [wallet, setWallet] = useState([]);
  const [transacts, setTransacts] = useState([]);
  const [currency, setCurrency] = useState("");
  const [userType, setUserType] = useState("");
  const [amount, setAmount] = useState(0);
  const [currencyError, setCurrencyError] = useState(false);
  const [amountError, setAmountError] = useState(false);
//   const [successnotification, setSuccessNotification] = useState("");
  const [dangernotification, setDangerNotification] = useState("");
  const { userData } = useContext(UserContext);
  let addThrot = false;
  const Navigate = useNavigate();
  const [Razorpay] = useRazorpay();
  const location = useLocation();
  useEffect(() => {
  }, [currency, amount]);

  useEffect(() => {
    updateUsers();
  }, [userData.userId]);
  useEffect(() => {
    if (location.state) {
      setUserType(location.state.userType);
    }
  }, [location.state]);
  const updateUsers = async () => {
    try {
      const res = await axios.get(
        `${API}/api/wallet/get-amounts/${userData.userId}`,
        {
          userId: userData.userId,
          headers: {
            token: userData.token,
          },
        }
      );

      setWallet((prevState) => {
        const sorted = res.data.sort((a, b) =>
          a.currencyCode.localeCompare(b.currencyCode)
        );
        return sorted;
      });

      const transactions = await axios.get(
        `${API}/api/transaction/get-counsellorTransactions`,
        { headers: { userId: userData.userId } }
      );
      setTransacts(transactions.data);
    } catch (error) {
      console.log(error);
    }
  };
  const addingAmount = async () => {
    const params = { amount: amount, currency:currency,userType:userType }; // Example parameters
    Navigate('/payment-screen', { state: params });
  }
    return (
        <>
    {dangernotification && (
        <div className="container mb-3 mt-3">
          <div className="row">
            <div className="alert alert-danger alert-dismissible">
              {" "}
              <a
                onClick={() => {
                  setDangerNotification("");
                }}
                className="close"
                data-dismiss="alert"
                aria-label="close"
              >
                &times;
              </a>
              {dangernotification}
            </div>
          </div>
        </div>
      )}
     <div className="container mb-3 mt-3">
     <div className="container center-algne mb-3">
        <div className="wallet-card">
            <div className="row ">
              <div className="col-12 mb-2">
                <select
                  className={`wallet-amount-btn btn-primary w-100 mb-2 ${
                    currencyError ? "is-invalid" : ""
                  }`}
                  onChange={(event) => {
                    setCurrency(() => event.target.value); // First statement
                    setCurrencyError(false); // Second statement
                  }}
                >
                  <option selected disabled>
                    {"Select Currency"}
                  </option>
                  {wallet.map((x, index) => (
                    <option>{x.currencyCode}</option>
                  ))}
                </select>
                {currencyError && (
                  <div className="invalid-feedback pb-3">
                    Please select a currency
                  </div>
                )}
              </div>
              {Walletadd.map((item, index) => (
                <div className="col-6 mb-2" key={index}>
                  <button
                    className={`wallet-amount-btn w-100 ${
                      amountError ? "is-invalid" : ""
                    }`}
                    type="button"
                    onClick={() => {
                      setAmount(() => item.amount);
                      setAmountError(false);
                    }}
                  >
                    {item.amount}
                  </button>
                </div>
              ))}
            <div className="col-6 mb-2">
            <input
                type="number"
                className={`form-control ${amountError ? "is-invalid" : ""}`}
                placeholder="Enter custom amount"
                value={amount} // Assuming 'amount' state is being used for custom input
                onChange={(e) => {
                setAmount(e.target.value);
                setAmountError(false); // Reset error if custom input is modified
                }}
            />
            </div>
              {amountError && (
                <div className="invalid-feedback d-block pb-3">
                  Please select an amount
                </div>
              )}
              <div className="col-12 mb-2">
                <button
                  className="wallet-amount-btn w-100"
                  onClick={() => {
                    if (!currency && amount == 0) {
                      setCurrencyError(true);
                      setAmountError(true);
                      return;
                    }

                    if (!currency) {
                      setCurrencyError(true);
                      return;
                    }
                    if (amount == 0) {
                      setAmountError(true);
                      return;
                    }

                    if (addThrot) {
                      return;
                    }
                    addThrot = true;
                    setTimeout(() => {
                      addThrot = false;
                    }, 5000);
                    addingAmount();
                  }}
                >
                  Add Amount
                </button>
              </div>
            </div>
        </div>
      </div>
    </div>
        </>
    )

}
export default Walletscreen;