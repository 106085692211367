import React from "react";
import SectionsHead from "../Home/Heading";
import "../../style/partials/components/_cookis.scss";

const Cookies = () => {
  return (
    <div className="container cookie-common mt-4 mb-5">
      <SectionsHead heading="Cookie" />
      <h5>
        The purpose of the cookie is to help the A2Z website keep track of your
        visits and activity. Many people find this useful so that they can store
        passwords on commonly used sites, or simply so they know what they have
        visited or downloaded in the past. Session cookies are used only when a
        person is actively navigating a website; once you leave the site, the
        session cookie disappears. Tracking cookies may be used to create
        long-term records of multiple visits to the same site. Authentication
        cookies track whether a user is logged in, and if so, under what name.
      </h5>
      <h4>Cookies We Collect As You Use Our Services</h4>
      <h5>Required Cookies</h5>
      <p>
        These cookies are required to enable core site functionality and are
        automatically enabled when you use this site.
      </p>
      <ul>
        <li>
          Remember log-in details, session state, and error messages, and
          provide secure log-in.
        </li>
        <li>
          Maintain site security: IP address, location, country, date/time, and
          interaction with our app.
        </li>
        <li>
          Keep a record of your most recent visit or to record your login
          information.
        </li>
        <li>
          Save the User's session and carry out other activities that are
          strictly necessary for the web operation in relation to the
          distribution of traffic.
        </li>
        <li>Remember your task or transaction progress.</li>
      </ul>
      <h4>What do we do with this information </h4>
      <p>
        Customize our services for you. Speed up and secure sign-up and sign-in
        processes and account information. Analyze user behaviour so we can
        improve existing services and build new services.
      </p>
      <h4>Third parties we use</h4>
      <p>
        The A2Z web monitors and analyzes web traffic internally and via the
        third-party services Google Analytics to keep track of website
        behaviour.
      </p>
      <h4>Advertising Cookies  </h4>
      <p>
        These cookies are used by us and third parties to serve ads that are
        relevant to your interests. The following companies require you to
        select your interest-based advertising preferences directly on their
        sites: Google, Facebook and Twitter.
      </p>
      <p>Required and Functional Cookies plus</p>
      <ul>
        <li>Provide you with interest-based offers or ads.</li>
        <li>
          Allows Google and its partners to provide you with interest-based ads.
        </li>
        <li>Allow you to share pages with social networks.</li>
        <li>Allow you to post comments.</li>
      </ul>
    </div>
  );
};

export default Cookies;
