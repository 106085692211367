import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import UserContext from "../../../contexts/UserContext";
import { API } from "../../../api-config";
import Heading from "../../Home/Heading";
import "..//../..//style/partials/Counsellor/notificationpage.scss";

let pageNum = 1;
const AllCounseleeNotifications = () => {
  const [notifications, setNotifications] = useState([]);
  const [pagesCount, setPageCount] = useState();
  const { userData, setUserData } = useContext(UserContext);
  const notificationLoad = async () => {
    const response = await axios.post(
      `${API}/api/notifications/getNotification`,
      {
        userId: userData.userId,
        limit: 50,
        offset: (pageNum - 1) * 50,
      },
      {
        headers: {
          token: userData.token,
        },
      }
    );
    //console.log(response.data);
    setNotifications(response.data.note[0].notification);
    setPageCount(Math.ceil(response.data.total / 50));
  };
  useEffect(() => {
    notificationLoad();
  }, []);
  const pageNums = (num) => {
    pageNum = num;
    notificationLoad();
  };
  const accomodatePage = (clickedPage) => {
    if (clickedPage <= 1) {
      return clickedPage + 1;
    }
    if (clickedPage >= pagesCount) {
      return clickedPage - 1;
    }
    return clickedPage;
  };

const formatDate = (isoString) => {
  const date = new Date(isoString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const year = date.getFullYear();

  const hours = String(date.getUTCHours()).padStart(2, '0');
  const minutes = String(date.getUTCMinutes()).padStart(2, '0');
  const seconds = String(date.getUTCSeconds()).padStart(2, '0');

  return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
};

  return (
    <>
      <div className="container mt-5 mb-5">
        <Heading heading="Notifications" />
        <div className="row pt-3 pb-3">
          {notifications.map((item, index) => (
            <div key={item._id} className=" shadow-sm all-noti-box">
              <p className="costom-w">{item.msg}</p>
              <p>{formatDate(item.date)}</p>
            </div>
          ))}
        </div>
      </div>
      {pagesCount > 1 && (
        <div>
          {pageNum > 1 && (
            <button
              className="index-btn mb-2 mt-2"
              onClick={() => {
                pageNums(pageNum - 1 >= 1 ? pageNum - 1 : 1);
              }}
            >
              {<i className="fa-solid fa-chevron-left"></i>}
            </button>
          )}
          {(() => {
            const buttons = [];
            if (pagesCount >= 3) {
              const currentPageNum = accomodatePage(pageNum);
              for (let i = -1; i < 2; i++) {
                buttons.push(
                  <button
                    key={currentPageNum + i}
                    onClick={() => {
                      pageNums(currentPageNum + i);
                    }}
                  >
                    {currentPageNum + i}
                  </button>
                );
              }
            } else {
              for (let i = 0; i < pagesCount; i++) {
                buttons.push(
                  <button
                    key={1 + i}
                    onClick={() => {
                      pageNums(1 + i);
                    }}
                  >
                    {1 + i}
                  </button>
                );
              }
            }
          })()}
          {pageNum != pagesCount && (
            <button
              className="index-btn mb-2 mt-2"
              onClick={() => {
                pageNums(pageNum + 1 <= pagesCount ? pageNum + 1 : pagesCount);
              }}
            >
              {<i className="fa-solid fa-chevron-right"></i>}
            </button>
          )}
        </div>
      )}
    </>
  );
};

export default AllCounseleeNotifications;
