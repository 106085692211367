import React, { useRef } from "react";
import { useState, useContext, useEffect } from "react";
import "..//..//style/partials/components/_accountfrom.scss";
import { FaFacebookF } from "react-icons/fa";
import { AiFillEye } from "react-icons/ai";
import { FcGoogle } from "react-icons/fc";
import { API } from "../../api-config";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";
import { jwtDecode } from "jwt-decode";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import UserContext from "../../contexts/UserContext.js";
import { useForm } from "react-hook-form";
// import GoogleLogin, { GoogleLoginProps } from "react-google-login";
import { gapi } from "gapi-script";
import { Modal } from "react-bootstrap";
import { LoginSocialFacebook } from "reactjs-social-login";
import Resetting from "../Password/Resetting.js";
// import { throttle } from "lodash";
import Load from "../Loading/Load.js";
let wait = false;
export default function Register() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [completeDetails, setCompleteDetails] = useState(false);
  const [email, setEmail] = useState();
  const [selectedTab, setSelectedTab] = useState("COUNSELLOR");
  const [isSignupVisible, setIsSignupVisible] = useState(true);
  const { setUserData } = useContext(UserContext);
  const [passwordShown, setPasswordShown] = useState(false);
  const entityRef = useRef();
  const [entity, setEntity] = useState();
  const [contact, setContact] = useState();
  const [isDisabled, setDisabled] = useState(false);
  const [pass, setPass] = useState(false);

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  // const {setprofile} = useContext(ProfileContext)

  const handleIsSignupVisible = (value) => {
    setIsSignupVisible((prevState) => !prevState);
  };
  const loadForgot = () => {
    navigate("/forgot-password");
  };
  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId: process.env.REACT_APP_CLIENT_ID,
        scope: "",
      });
      gapi.load("client:auth2", start);
    }
  });
  const onSubmit = (data) => {
    // debugger
    setDisabled(true);
    // data.trim()
    if (!isSignupVisible) {
      try {
        axios
          .post(`${API}/api/frontregi/login`, data)
          .then((response) => {
            localStorage.setItem("userData", JSON.stringify(response.data));
            // sessionStorage.setItem('userData', JSON.stringify(response.data));
            console.log(
              "TRY CONSOLLING",
              jwtDecode(response.data.token),
              "UUSSEERRDAATTTAAA"
            );
            setUserData(response.data);
            alertify.success("Welcome back", response.data.fName);
            if (response.data.flag === "STUDENT") {
              navigate("/students", { replace: true }, response.data);
            } else {
              navigate("/counsellor", { replace: true }, response.data);
            }
          })
          .catch((err) => {
            console.log(err.response.data, "test");
            alertify.error("User not found");
          });
      } catch (err) {
        console.log("tes", err);
        alertify.error("Error message");
      }
    } else {
      data.flag = selectedTab;
      axios
        .post(`${API}/api/frontregi/register`, data)
        .then((response) => {
          // console.log("1 ", response.data);
          // localStorage.setItem("userData", JSON.stringify(response.data));
          // //sessionStorage.setItem('userData', JSON.stringify(response.data));
          // setUserData(response.data);
          // console.log("1 ", response.data);
          // axios
          //   .post(`${API}/api/wallet/wallet-register`, {
          //     params: { userData: response.data },
          //   })
          //   .then((response) => {
          //     console.log(response);
          //   })
          //   .catch((err) => {
          //     console.log(err);
          //   });
          // console.log("2 ", response.data);
          // axios
          //   .post(`${API}/api/notifications/new`, { userData: response.data })
          //   .then((response) => {
          //     console.log(response);
          //   })
          //   .catch((err) => {
          //     console.log(err);
          //   });
          // console.log("3 ", response.data);
          // if (response.data.flag == "COUNSELLOR") {
          //   axios
          //     .post(`${API}/api/rating/create`, { userData: response.data })
          //     .then((response) => {
          //       console.log(response);
          //     })
          //     .catch((err) => {
          //       console.log(err);
          //     });
          // }
          // alertify.success("Registration Successful");
          // if (response.data.flag === "STUDENT") {
          //   navigate("/students", { replace: true }, response.data);
          // } else {
          //   navigate("/counsellor", { replace: true }, response.data);
          // }
          console.log("RETURNED");
          navigate("/verification", { replace: true });
        })
        .catch((err) => {
          console.log("err", err);
          alertify.error(err.response.data.msg);
        });
    }
  };
  // const throttledSubmitData = throttle(onSubmit, 2000);

  const changeFrom = (formType) => {
    setSelectedTab(formType);
  };

  const successGoogleLogin = async (res) => {
    try {
      axios.post(`${API}/api/frontregi/sociallogin`, res).then((resp) => {
        if (resp.data.existing) {
          localStorage.setItem("userData", JSON.stringify(resp.data));
          // sessionStorage.setItem('userData', JSON.stringify(response.data));
          setUserData(resp.data);
          if (resp.data.flag === "STUDENT") {
            console.log("RETURNED");
            setIsLoading(false);
            navigate("/students", { replace: true }, resp.data);
          } else {
            console.log("RETURNEDDD");
            setIsLoading(false);
            navigate("/counsellor", { replace: true }, resp.data);
          }
        } else {
          setEmail((prevState) => res.profileObj.email);
          setCompleteDetails((prevState) => true);
        }
      });
    } catch (error) {
      alertify.error(error.response.data.msg);
    }
  };

  const successFacebookLogin = async (response) => {
    try {
      console.log("RESPONSE AFTER FACEBOOK LOGIN", response);
      const resp = await axios.post(`${API}/api/frontregi/sociallogin`, {
        email: response.data.email,
        fName: response.data.first_name,
        lName: response.data.last_name,
        facebookId: response.data.userID,
        socialProvider: "facebook",
        res: response,
      });
      console.log("RREESSPP", resp.data);
      if (resp.data.existing) {
        localStorage.setItem("userData", JSON.stringify(resp.data));
        console.log("RREESSPP", resp.data);
        setUserData(resp.data);
        if (resp.data.flag === "STUDENT") {
          console.log("RETURNED");
          setIsLoading(false);
          navigate("/students", { replace: true }, resp.data);
        } else {
          console.log("RETURNEDDD");
          setIsLoading(false);
          navigate("/counsellor", { replace: true }, resp.data);
        }
      } else {
        setEmail((prevState) => response.data.email);
        setCompleteDetails((prevState) => true);
      }
    } catch (error) {
      alertify.error(error.response.data.msg);
    }
  };
  return (
    <div>
      {isLoading && <Load />}
      {!isLoading && (
        <section className="text-center">
          <div className="p-5 bg-image">
            <div className="title">
              <span>A2Z</span>
            </div>
          </div>
          {completeDetails && (
            <Modal show={completeDetails}>
              <Modal.Body>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    axios
                      .post(`${API}/api/frontregi/completeReg`, {
                        phone: contact,
                        flag: entity ? entity : entityRef.current.value,
                        email: email,
                      })
                      .then((responseC) => {
                        localStorage.setItem(
                          "userData",
                          JSON.stringify(responseC.data)
                        );
                        // sessionStorage.setItem('userData', JSON.stringify(response.data));
                        console.log("responseC.data", responseC.data);
                        setUserData(responseC.data);
                        console.log("SET NEW DATA".responseC.data);
                        if (responseC.data.flag === "STUDENT") {
                          console.log("RETURNED STUDENT");
                          navigate(
                            "/students",
                            { replace: true },
                            responseC.data
                          );
                        } else {
                          console.log("RETURNEDDD COUNSELLOR");
                          navigate(
                            "/counsellor",
                            { replace: true },
                            responseC.data
                          );
                        }
                      });
                  }}
                >
                  <select
                    ref={entityRef}
                    onChange={(e) =>
                      setEntity((prevState) => {
                        if (e.target.value == "Counselee") {
                          return "STUDENT";
                        } else {
                          return "COUNSELLOR";
                        }
                      })
                    }
                  >
                    <option value={"STUDENT"}>Counselee</option>
                    <option value={"COUNSELLOR"}>Counsellor</option>
                  </select>
                  <input
                    type="number"
                    onKeyDown={(e) => {
                      console.log(e.key, " ,", e.keyCode);
                      if (
                        e.keyCode == 38 ||
                        e.keyCode == 37 ||
                        e.keyCode == 39 ||
                        e.keyCode == 40
                      ) {
                        e.preventDefault();
                      }
                    }}
                    onWheel={(e) => {
                      e.preventDefault();
                    }}
                    placeholder="Enter your contact number"
                    onChange={(e) => {
                      console.log(e.target.value);
                      setContact((prevState) => e.target.value);
                    }}
                  ></input>
                  <button type="submit">Sumbit</button>
                </form>
              </Modal.Body>
            </Modal>
          )}
          <div className="container">
            <div className="row">
              <div className="col-12 m-auto login-center-div">
                <div className="formcard mx-4 pd shadow-5-strong login-50">
                  <div className="card-body card-border">
                    <div className="row d-flex justify-content-center">
                      <div>
                        <div className="row mb-3">
                          {isSignupVisible && (
                            <div className="col-lg-12">
                              <div className="slide-controls register-radio">
                                <input
                                  type="radio"
                                  name="slide"
                                  id="login"
                                  onClick={() => changeFrom("COUNSELLOR")}
                                  // checked={selectedTab === "COUNSELLOR"}
                                />
                                <input
                                  type="radio"
                                  name="slide"
                                  id="signup"
                                  onClick={() => changeFrom("STUDENT")}
                                  // checked={selectedTab === "STUDENT"}
                                />
                                <label htmlFor="login" className="slide login">
                                  COUNSELLOR
                                </label>
                                <label
                                  htmlFor="signup"
                                  className="slide signup"
                                >
                                  COUNSELEE
                                </label>
                                <div className="slider-tab" />
                              </div>
                            </div>
                          )}
                        </div>
                        <form
                          onSubmit={handleSubmit((data) => {
                            if (wait) {
                              return;
                            }
                            wait = true;
                            setTimeout(() => {
                              wait = false;
                            }, 5000);
                            onSubmit(data);
                          })}
                        >
                          {/* 2 column grid layout with text inputs for the first and last names */}
                          {isSignupVisible && (
                            <div className="row">
                              <div className="col-md-12 mb-3">
                                <div className="form-outline">
                                  <input
                                    className="form-control"
                                    placeholder="First Name"
                                    onKeyPress={(e) => {
                                      if (
                                        e.target.value === "" &&
                                        e.key === " "
                                      ) {
                                        e.preventDefault();
                                      }
                                    }}
                                    {...register("fName", {
                                      validate: (value) => value.trim() !== "",
                                      required: true,
                                      maxLength: 20,
                                      pattern:
                                        /^[a-zA-Z\s]*[a-zA-Z][a-zA-Z\s]*$/,
                                    })}
                                  />
                                  {errors?.fName?.type === "required" && (
                                    <p className="valid-txt">
                                      Please Enter First Name
                                    </p>
                                  )}
                                  {errors?.fName?.type === "pattern" && (
                                    <p className="valid-txt">
                                      Alphabetical characters only
                                    </p>
                                  )}
                                </div>
                              </div>
                              <div className="col-md-12 mb-3">
                                <div className="form-outline">
                                  <input
                                    className="form-control"
                                    placeholder="Last Name"
                                    onKeyPress={(e) => {
                                      if (
                                        e.target.value === "" &&
                                        e.key === " "
                                      ) {
                                        e.preventDefault();
                                      }
                                    }}
                                    {...register("lName", {
                                      required: true,
                                      maxLength: 20,
                                      pattern:
                                        /^[a-zA-Z\s]*[a-zA-Z][a-zA-Z\s]*$/,
                                    })}
                                  />
                                  {errors?.lName?.type === "required" && (
                                    <p className="valid-txt">
                                      Please Enter Last Name
                                    </p>
                                  )}
                                  {errors?.lName?.type === "pattern" && (
                                    <p className="valid-txt">
                                      Alphabetical characters only
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>
                          )}
                          {/* Email input */}
                          <div className="form-outline mb-3">
                            <input
                              type="email"
                              className="form-control"
                              placeholder="Enter Your Email"
                              onKeyPress={(e) => {
                                if (e.target.value === "" && e.key === " ") {
                                  e.preventDefault();
                                }
                              }}
                              {...register("email", {
                                required: true,
                                pattern: {
                                  value:
                                    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                  message: "Invalid Email Address",
                                },
                              })}
                            />
                            {errors?.email?.type === "required" && (
                              <p className="valid-txt">Please Enter Email Address</p>
                            )}
                            <p className="valid-txt">{errors.email?.message}</p>
                          </div>
                          {isSignupVisible && (
                            <div className="form-outline mb-3">
                              <input
                                type="number"
                                className="form-control"
                                placeholder="Enter Your Mobile Number"
                                {...register("mobileNo", {
                                  required: true,
                                  maxLength: 13,
                                  minLength: 10,
                                })}
                                onKeyDown={(e) => {
                                  console.log(e.key, " ,", e.keyCode);
                                  if (
                                    e.keyCode == 38 ||
                                    e.keyCode == 37 ||
                                    e.keyCode == 39 ||
                                    e.keyCode == 40
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                                onWheel={(e) => {
                                  e.preventDefault();
                                }}
                                aria-invalid={
                                  errors.mobileNo ? "true" : "false"
                                }
                              />
                              {errors?.mobileNo?.type === "required" && (
                                <p className="valid-txt">
                                  Please Enter Mobile Number
                                </p>
                              )}
                              {errors?.mobileNo?.type === "minLength" && (
                                <p className="valid-txt">
                                  Please Enter Vaild MobileNo
                                </p>
                              )}
                              {errors?.mobileNo?.type === "maxLength" && (
                                <p className="valid-txt">
                                  Max 13 digit with Country Code
                                </p>
                              )}
                            </div>
                          )}
                          {/* Password input */}
                          <div className="form-outline mb-3 i-posi">
                            <input
                              type={passwordShown ? "text" : "password"}
                              className="form-control"
                              placeholder="Enter Your Password"
                              onKeyPress={(e) => {
                                if (e.target.value === "" && e.key === " ") {
                                  e.preventDefault();
                                }
                              }}
                              {...register("password", {
                                required: true,
                              })}
                            />
                            <i onClick={togglePasswordVisiblity}>
                              {<AiFillEye />}
                            </i>
                            {errors?.password?.type === "required" && (
                              <p className="valid-txt">Please Enter Password</p>
                            )}
                          </div>
                          {/* Checkbox */}
                          <div className="form-check d-flex  mb-3">
                            <input
                              className="form-check-input mr-2"
                              type="checkbox"
                              defaultValue
                              id="form2Example33"
                            />
                            <label className="form-check-label">
                              Remember Me
                            </label>
                          </div>
                          {/* Submit button */}
                          <div className="d-grid gap-2 mb-3">
                            <button
                              type="submit"
                              value="Register"
                              className="btn reg-btn"
                              style={{ borderRadius: "30px" }}
                            >
                              {isSignupVisible ? "Signup" : "Login"}
                            </button>

                            {!isSignupVisible && (
                              <a
                                href="#"
                                onClick={(e) => {
                                  e.preventDefault();
                                  loadForgot();
                                }}
                                className="reg-link"
                              >
                                Forgot Password
                              </a>
                            )}
                          </div>
                          <div className="text-center">
                            <div className="form_foot">
                              <p>OR</p>
                            </div>
                            <div className="favicons">
                             
                              <LoginSocialFacebook
                                appId="1608632773257693"
                                onResolve={async (response) => {
                                  setIsLoading(true);
                                  successFacebookLogin(response);
                                }}
                                onReject={(error) => {
                                  console.log(error);
                                }}
                              >
                                <FaFacebookF style={{ color: "#3b5998" }} />
                              </LoginSocialFacebook>
                            </div>
                          </div>
                          <div className="form_head">
                            <div>
                              {isSignupVisible ? (
                                <div className="form_foot-log mt-3">
                                  <p>Already have an account?</p>
                                </div>
                              ) : (
                                <div className="form_foot-nw mt-3">
                                  <p>New to A2Z?</p>
                                </div>
                              )}

                              <button
                                type="button"
                                className="fom-lnk mt-4"
                                onClick={handleIsSignupVisible}
                                // disabled={isDisabled}
                              >
                                {isSignupVisible
                                  ? "Login"
                                  : "Create an account"}
                              </button>
                            </div>
                          </div>
                        </form>
                        {/* {pass && (
                        <Resetting
                          onSet={() => {
                            setPass((prevState) => !prevState);
                          }}
                        />
                      )} */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </div>
  );
}
