import React, { useState, useEffect, useContext, createContext } from "react";
import axios from "axios";
import { API } from "../api-config";

const ReviewContext = createContext();

export const Reviews = ({ children }) => {
  const [review, setReview] = useState([]);
  useEffect(() => {
    const fetchReview = async () => {
      try {
        const feedbackList = await axios.get(
          `${API}/api/feedback/all-feedback`
        );

        setReview(feedbackList);
      } catch (error) {
        console.log(error);
      }
    };
    fetchReview();
  }, []);
  return (
    <ReviewContext.Provider value={{ review }}>
      {children}
    </ReviewContext.Provider>
  );
};
export default ReviewContext;
