import React, { useContext, useState, useEffect, createContext } from "react";
import axios from "axios";
import { API } from "../api-config";
//import { ApiContext } from "../contexts/ApiContext";
import Loader from "../components/Loader/Loader";

const CounsellorContext = createContext();

export function CounsellorContextProvider({ children }) {
  const [users, setUsers] = useState(null);
  useEffect(() => {
    async function fetchData() {
      try {
        const { data } = await axios.post(
          `${API}/api/counselor-profile/get-counselor`,
          { offset: 0 }
        );
        //console.log(data);
        setUsers(data.userpros[0].userpros);
      } catch (error) {
        //console.log(error);
      }
    }
    fetchData();
  }, []);
  if (!users) return <Loader />;

  return (
    <CounsellorContext.Provider
      value={{
        users,
      }}
    >
      {children}
    </CounsellorContext.Provider>
  );
}
export function useAPI() {
  const context = useContext(CounsellorContext);
  if (context === undefined) {
    throw new Error("Context must be used within a Provider");
  }
  return context;
}
