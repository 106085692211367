import React, { useState } from "react";
import "../../style/partials/components/_Elements.scss";

const Button = ({
  type = "button",
  className = "",
  context,
  onClick,
  onSubmit,
}) => {
  return (
    <div className="det-button mt-3 w-100">
      <button
        type={type}
        className={`btn btn-outline-primary custom-btn btn-8`}
        onClick={onClick}
        onSubmit={onSubmit}
      >
        {context}
      </button>
    </div>
  );
};
export default Button;
