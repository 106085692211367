import React, { useContext } from "react";
import SectionsHead from "../Home/Heading";
import "../../style/partials/components/_howitworks.scss";
// import { PiChalkboardTeacherThin } from "react-icons/pi";
import { MdPriceChange } from "react-icons/md";
import { MdOutlinePriceCheck } from "react-icons/md";
import { FaNetworkWired } from "react-icons/fa";
import { RiFocus2Line } from "react-icons/ri";
import { GiTeacher } from "react-icons/gi";
import { RiTimeLine } from "react-icons/ri";
import { FcTimeline } from "react-icons/fc";
import { AiFillInteraction } from "react-icons/ai";
import UserContext from "../../contexts/UserContext";

const HowItWorksSect = () => {
  const { userData } = useContext(UserContext);
  return (
    <div className="container mb-5 work-info" defer>
      <SectionsHead heading="HOW IT WORK" />
      {userData?.flag == "COUNSELLOR" ? (
        <div className="row d-flex hiw-list for-four-option pt-3 pb-0">
          <div className="col-xl-2 col-md-4 container" data-aos="fade-up-right">
            <div className="hover-efect-card-section">
              <div className=" feature-box-icon small icn-holder">
                <div className="logo-back">
                  <MdPriceChange />
                </div>
              </div>
            </div>
            <div className=" info-partitions ">
              <div className="body">
                <h5> Create a Counselor Profile:</h5>
                <div className="feature-box-line"></div>
                <p className="text">
                  Establish your professional presence by meticulously detailing
                  your Counselor Profile. Include essential information such as
                  your Profile Name, Company, Selected Skills, Experience,
                  Alternate Contact Number, Country, State, City, Zip Code,
                  Address, and a comprehensive Description. Elevate your profile
                  with document uploads, such as portfolios.
                </p>
              </div>
              {/* <div className="card-footer">
              <a
                onClick={() => {
                  getHelp("general");
                }}
                className="card-link"
              >
                Know More
              </a>
            </div> */}
            </div>
          </div>
          <div className="col-xl-2 col-md-4 container">
            <div className="hover-efect-card-section">
              <div className=" feature-box-icon small icn-holder">
                <div className="logo-back">
                  {" "}
                  <GiTeacher />
                </div>
              </div>
            </div>
            <div className="info-partitions ">
              <div className="body">
                <h5>Post Counseling</h5>
                <div className="feature-box-line"></div>
                <p className="text">
                  Seamlessly post a new counselling session by providing a
                  session title, a detailed session description, and specifying
                  relevant skills. Add multiple sessions with estimated numbers
                  and prices, ensuring each session is priced above 499, After
                  you finish posting your session, it will be published and go
                  live on the platform. After publishing, you can still make
                  changes to the price, skills, and other relevant details.
                </p>
              </div>
              {/* <div className="card-footer">
              <a
                onClick={() => {
                  getHelp("general");
                }}
                className="card-link"
              >
                Know More
              </a>
            </div> */}
            </div>
          </div>
          <div className="col-xl-2 col-md-4 container">
            <div className="hover-efect-card-section">
              <div className=" feature-box-icon small icn-holder">
                <div className="logo-back">
                  <FaNetworkWired />
                </div>
              </div>
            </div>
            <div className="info-partitions ">
              <div className="body">
                <h5>Dynamic Session Bidding</h5>
                <div className="feature-box-line"></div>
                <p className="text">
                  Experience the dynamic pricing model where counsellors set
                  session prices based on specific skills. Counselees can bid on
                  sessions by entering their preferred price, creating a
                  personalised and empowering experience.
                </p>
              </div>
              {/* <div className="card-footer">
              <a
                onClick={() => {
                  getHelp("general");
                }}
                className="card-link"
              >
                Know More
              </a>
            </div> */}
            </div>
          </div>
          <div className="col-xl-2 col-md-4 container">
            <div className="hover-efect-card-section">
              <div className=" feature-box-icon small icn-holder">
                <div className="logo-back">
                  <RiFocus2Line />
                </div>
              </div>
            </div>
            <div className="info-partitions ">
              <div className="body">
                <h5>Manage Sessions with Precision</h5>
                <div className="feature-box-line"></div>
                <p className="text">
                  Exercise control over your counselling sessions by accepting
                  or rejecting requests based on bids provided by Counselees.
                  Upon acceptance, schedule session times and dates with
                  Counselees. Conduct sessions on esteemed platforms such as
                  Google Meet or other digital mediums. After the completion of
                  sessions, funds are seamlessly deposited into your
                  counsellor's wallet.
                </p>
              </div>
              {/* <div className="card-footer">
              <a
                onClick={() => {
                  getHelp("general");
                }}
                className="card-link"
              >
                Know More
              </a>
            </div> */}
            </div>
          </div>
          {/* <div className="col-xl-2 col-md-4 container">
            <div className="hover-efect-card-section">
              <div className=" feature-box-icon small icn-holder">
                <div className="logo-back">
                  <FcTimeline />
                </div>
              </div>
            </div>
            <div className="info-partitions ">
              <div className="body">
                <h5> In-depth Feedback</h5>
                <div className="feature-box-line"></div>
                <p className="text">
                  Counselees provide detailed feedback upon session completion.
                  Share valuable insights into the counselling experience and
                  the counsellor's performance.
                </p>
              </div> */}
          {/* <div className="card-footer">
              <a
                onClick={() => {
                  getHelp("general");
                }}
                className="card-link"
              >
                Know More
              </a>
            </div> */}
          {/* </div>
          </div>
          <div className="col-xl-2 col-md-4 container">
            <div className="hover-efect-card-section">
              <div className=" feature-box-icon small icn-holder">
                <div className="logo-back">
                  <AiFillInteraction />
                </div>
              </div>
            </div>
            <div className="info-partitions ">
              <div className="body">
                <h5>Feedback</h5>
                <div className="feature-box-line"></div>
                <p className="text">
                  Contribute to our commitment to continuous improvement by
                  providing constructive feedback after completing your
                  sessions. Share insights on the session experience and the
                  counsellor's performance, fostering a transparent and
                  accountable counselling environment.
                </p>
              </div> */}
          {/* <div className="card-footer">
              <a
                onClick={() => {
                  getHelp("general");
                }}
                className="card-link"
              >
                Know More
              </a>
            </div> */}
          {/* </div> */}
          {/* </div> */}
        </div>
      ) : (
        <div className="row d-flex hiw-list for-four-option pt-3 pb-0">
          <div className="col-xl-2 col-md-4 container" data-aos="fade-up-right">
            <div className="hover-efect-card-section">
              <div className=" feature-box-icon small icn-holder">
                <div className="logo-back">
                  <MdPriceChange />
                </div>
              </div>
            </div>
            <div className=" info-partitions ">
              <div className="body">
                <h5>Skill Selection</h5>
                <div className="feature-box-line"></div>
                <p className="text">
                  Explore our diverse range of skills and make use of our
                  advanced search and filtering features to discover the ideal
                  match for your unique requirements, whether they involve
                  career advancement, financial well-being, IT proficiency,
                  personal development, or other specialised areas.
                </p>
              </div>
              {/* <div className="card-footer">
              <a
                onClick={() => {
                  getHelp("general");
                }}
                className="card-link"
              >
                Know More
              </a>
            </div> */}
            </div>
          </div>
          <div className="col-xl-2 col-md-4 container">
            <div className="hover-efect-card-section">
              <div className=" feature-box-icon small icn-holder">
                <div className="logo-back">
                  {" "}
                  <GiTeacher />
                </div>
              </div>
            </div>
            <div className="info-partitions ">
              <div className="body">
                <h5>Counsellor Selection</h5>
                <div className="feature-box-line"></div>
                <p className="text">
                  Utilize our sophisticated search and filter functionalities to
                  effortlessly identify counsellors based on their
                  specialization, availability, and specific criteria. Benefit
                  from a diverse pool of counsellors, each verified and
                  professionally experienced in various niches, providing users
                  with an expansive and high-quality selection.
                </p>
              </div>
              {/* <div className="card-footer">
              <a
                onClick={() => {
                  getHelp("general");
                }}
                className="card-link"
              >
                Know More
              </a>
            </div> */}
            </div>
          </div>
          <div className="col-xl-2 col-md-4 container">
            <div className="hover-efect-card-section">
              <div className=" feature-box-icon small icn-holder">
                <div className="logo-back">
                  <FaNetworkWired />
                </div>
              </div>
            </div>
            <div className="info-partitions ">
              <div className="body">
                <h5>Dynamic Session Bidding</h5>
                <div className="feature-box-line"></div>
                <p className="text">
                  Experience the dynamic pricing model where counsellors set
                  session prices based on specific skills. Counselees can bid on
                  sessions by entering their preferred price, creating a
                  personalised and empowering experience.
                </p>
              </div>
              {/* <div className="card-footer">
              <a
                onClick={() => {
                  getHelp("general");
                }}
                className="card-link"
              >
                Know More
              </a>
            </div> */}
            </div>
          </div>
          <div className="col-xl-2 col-md-4 container">
            <div className="hover-efect-card-section">
              <div className=" feature-box-icon small icn-holder">
                <div className="logo-back">
                  <RiFocus2Line />
                </div>
              </div>
            </div>
            <div className="info-partitions ">
              <div className="body">
                <h5>Payment</h5>
                <div className="feature-box-line"></div>
                <p className="text">
                  Counsellors then review and respond to the Counselee’s bids,
                  accepting or rejecting them accordingly. Once accepted,
                  counselees seamlessly add the amount to their wallets to
                  proceed with secure payments via debit cards, credit cards,
                  net banking, UPI, or wallets.
                </p>
              </div>
              {/* <div className="card-footer">
              <a
                onClick={() => {
                  getHelp("general");
                }}
                className="card-link"
              >
                Know More
              </a>
            </div> */}
            </div>
          </div>
          <div className="col-xl-2 col-md-4 container">
            <div className="hover-efect-card-section">
              <div className=" feature-box-icon small icn-holder">
                <div className="logo-back">
                  <FcTimeline />
                </div>
              </div>
            </div>
            <div className="info-partitions ">
              <div className="body">
                <h5>Effortless Counseling Sessions</h5>
                <div className="feature-box-line"></div>
                <p className="text">
                  Engage in counselling sessions with ease. After completing
                  session payments, counsellors schedule sessions, determining
                  the time and date. Sessions can be conducted on platforms such
                  as Google Meet and other preferred virtual spaces.
                </p>
              </div>
              {/* <div className="card-footer">
              <a
                onClick={() => {
                  getHelp("general");
                }}
                className="card-link"
              >
                Know More
              </a>
            </div> */}
            </div>
          </div>
          <div className="col-xl-2 col-md-4 container">
            <div className="hover-efect-card-section">
              <div className=" feature-box-icon small icn-holder">
                <div className="logo-back">
                  <AiFillInteraction />
                </div>
              </div>
            </div>
            <div className="info-partitions ">
              <div className="body">
                <h5>Feedback</h5>
                <div className="feature-box-line"></div>
                <p className="text">
                  Contribute to our commitment to continuous improvement by
                  providing constructive feedback after completing your
                  sessions. Share insights on the session experience and the
                  counsellor's performance, fostering a transparent and
                  accountable counselling environment.
                </p>
              </div>
              {/* <div className="card-footer">
              <a
                onClick={() => {
                  getHelp("general");
                }}
                className="card-link"
              >
                Know More
              </a>
            </div> */}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default HowItWorksSect;
