import React, { useContext, useState, useEffect, createContext } from "react";
import axios from "axios";
import { API } from "../api-config";
//import { ApiContext } from "../contexts/ApiContext";
import Loader from "../components/Loader/Loader";

const SkillsContext = createContext();

export function SkillsContextProvider({ children }) {
  const [skills, setSkills] = useState(null);
  useEffect(() => {
    async function fetchData() {
      try {
        const { data } = await axios.get(
          `${API}/api/skillslist/list-skills`,
          {}
        );
        setSkills(data);
        // console.log(data);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, []);
  if (!skills) return <Loader />;

  return (
    <SkillsContext.Provider
      value={{
        skills,
      }}
    >
      {children}
    </SkillsContext.Provider>
  );
}
export function useAPI() {
  const context = useContext(SkillsContext);
  if (context === undefined) {
    throw new Error("Context must be used within a Provider");
  }
  return context;
}
