import React from "react";
import { useLocation } from "react-router-dom";
import SectionsHead from "../Home/Heading";
import "../../style/partials/components/_howitworks.scss";
// import { PiChalkboardTeacherThin } from "react-icons/pi";
import { MdPriceChange } from "react-icons/md";
import { MdOutlinePriceCheck } from "react-icons/md";
import { FaNetworkWired } from "react-icons/fa";
import { RiFocus2Line } from "react-icons/ri";
import { GiTeacher } from "react-icons/gi";
import { RiTimeLine } from "react-icons/ri";
import { FcTimeline } from "react-icons/fc";
import { AiFillInteraction } from "react-icons/ai";
import HowItWorksSect from "./HowItWorksSect";

const HowItWorks = () => {
  const emp = useLocation();
  const answer = (i) => {
    const ele = document.getElementsByClassName(i);
    console.log(ele);
    ele.classList.toggle("display");
  };
  return (
    <>
      <div className="guideline-headline">
        <div className="container">
          <h2 className=" container mb-3">Skill Specific Session</h2>
        </div>
      </div>

      <div className="container">
        <div className="guideline-tag ">
          <div className="v-tagline-box v-tagline-box-v1 v-box-shadow shadow-effect-2">
            <h5>
              {emp.state.title
                ? emp.state.title
                : "Skill specific flexible session with deep and diverse concepts"}
            </h5>
            <p>
              {emp.state.description
                ? emp.state.description
                : " At A2Z, we belive that every students has a different approch towards learning as each separate individuals are unique and <b>customised</b> our modules in the manner to fulfill the needs of all the learners."}
            </p>
          </div>
        </div>
      </div>
      <HowItWorksSect />
    </>
  );
};
export default HowItWorks;
