import React, { useEffect } from "react";
import axios from "axios";

const Activation = () => {
  useEffect(() => {
    // const response = await axios.post("")
  }, []);
  return (
    <div>
      <h1>Account Activated , Please login to continue </h1>
    </div>
  );
};

export default Activation;
