import React from "react";
import Certified from "../../images/Out-of-the-Box-Solutions2.png";
import Deadline from "../../images/Always-Abiding-by-the-Deadline3.png";
import Transparency from "../../images/Transparency2.png";
import Solutions from "../../images/Certified-Experts.png";
import vesion from "../../images/our-vesion.png";
import vesion2 from "../../images/Our-Vision-Ooze-IT-Solutions.png";
import "../../style/partials/components/_About-us.scss";
import SectionsHead from "../Home/Heading";

const Aboutus = () => {
  return (
    <div className="container mt-5 mb-5">
      <SectionsHead heading="About Us" />
      <div className="aboutus-summery  top-title">
        <h6>
          Ooze IT Solutions is a tech-enabled company that builds digital
          products for all your needs. We specialize in catering services like
          customized Web Development, App Development, Digital Marketing, and
          E-commerce solutions. Ooze IT’s professionals create custom strategies
          for each of our clients based on their goals and needs. For the last
          ten years, we have consistently provided customized solutions to our
          clients, enabling them to achieve result-oriented growth. We are as
          enthusiastic as you to build tech-enabled solutions that could not
          only expand your business but modify the world. We want to help you
          achieve your goals by hand-holding you in each step of your business
          journey as a go-to-tech partner.
        </h6>
      </div>

      <div className="Why-You-Need">
        <h3>Why Do You Need Ooze IT Solutions?</h3>
        <div className="row hit-line-div">
          <div className="card-img-title col-lg-3 col-md-6 ">
            <div className="img-div-1">
              <img
                src={Certified}
                className=" feature-box-icon small icn-holder"
                alt="certify"
                loading="lazy"
              ></img>
            </div>
            <div className="title-div-1">
              <h4>Out of the Box Solutions</h4>
            </div>
          </div>

          <div className="card-img-title col-lg-3 col-md-6">
            <div className="img-div-1">
              <img
                src={Deadline}
                className=" feature-box-icon small icn-holder"
                alt="Deadline"
                loading="lazy"
              ></img>
            </div>
            <div className="title-div-1">
              <h4>Always Abiding by the Deadline</h4>
            </div>
          </div>
          <div className="card-img-title col-lg-3 col-md-6">
            <div className="img-div-1">
              <img
                src={Transparency}
                className=" feature-box-icon small icn-holder"
                alt="Transparency"
                loading="lazy"
              ></img>
            </div>
            <div className="title-div-1">
              <h4>Transparency</h4>
            </div>
          </div>
          <div className="card-img-title col-lg-3 col-md-6">
            <div className="img-div-1">
              <img
                src={Solutions}
                alt="Solutions"
                loading="lazy"
                className=" feature-box-icon small icn-holder"
              ></img>
            </div>
            <div className="title-div-1">
              <h4>Certified Experts</h4>
            </div>
          </div>
        </div>
      </div>

      <div className="our-vision row">
        <div className="col-md-6">
          <h2>Our Vision</h2>
          <p>
            Our vision is to be the leader in providing tech-savvy web solutions
            to our clients and want them to be the leader in their industry.
            Ooze IT Solutions culture prioritizes our client’s goals of being a
            brand. Always aiming for an experience-led approach to help brands
            with digital products designed for generating revenue. We are proud
            of ourselves after seeing our client’s success and will be happy to
            see yours.
          </p>
        </div>
        <div className="vision-img col-md-6">
          <img src={vesion} className="" alt="vesion" loading="lazy"></img>
        </div>
      </div>

      <div className="our-mission row">
        <div className="mission-img col-md-6">
          <img src={vesion2} className="" alt="vesion2" loading="lazy"></img>
        </div>
        <div className=" col-md-6">
          <h2>Our Mission</h2>
          <p>
            From the beginning, our primary goal was to help businesses reach a
            new level with our products and services. Offer customized web and
            marketing solutions to all types of business requirements & cater to
            them most efficiently! Building products that are helpful for
            brands, making the world better, for everyone, including ourselves.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Aboutus;
