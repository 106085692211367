import React from "react";
import SectionsHead from "../../Home/Heading";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "..//..//..//style/partials/Students/_aboutcounselling.scss";

const AboutSession = () => {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <div>
      <>
        <SectionsHead heading="FAQs" />
        <div className="container mt-4 mb-5">
          <Accordion
            className="acor-abut"
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
          >
            <AccordionSummary
              className="acor-summ"
              expandIcon={<ExpandMoreIcon className="acor-ico" />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography
                className="acor-titl"
                sx={{ flexShrink: 0 }}
                component="span"
              >
                How do I post a counselling session on A2Z?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="footer-link" component="span">
                Initiate a new counselling session by providing a title, session
                description, relevant skills, and estimated numbers and prices.
                Ensure each session is priced above rs 499 before posting.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            className="acor-abut"
            expanded={expanded === "panel2"}
            onChange={handleChange("panel2")}
          >
            <AccordionSummary
              className="acor-summ"
              expandIcon={<ExpandMoreIcon className="acor-ico" />}
              aria-controls="panel2bh-content"
              id="panel2bh-header"
            >
              <Typography
                className="acor-titl"
                sx={{ flexShrink: 0 }}
                component="span"
              >
                How do counsellors and counselees schedule sessions on A2Z?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="footer-link" component="span">
                Once a session is accepted, counselors and counselees
                collaborate to schedule session times and dates. Sessions can be
                conducted within our platform.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            className="acor-abut"
            expanded={expanded === "panel3"}
            onChange={handleChange("panel3")}
          >
            <AccordionSummary
              className="acor-summ"
              expandIcon={<ExpandMoreIcon className="acor-ico" />}
              aria-controls="panel3bh-content"
              id="panel3bh-header"
            >
              <Typography
                className="acor-titl"
                sx={{ flexShrink: 0 }}
                component="span"
              >
                How is the money transferred to the counsellor?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="footer-link" component="span">
                After a session is completed, the agreed-upon amount is
                seamlessly transferred to the counsellor's wallet, ensuring a
                smooth and secure transaction.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            className="acor-abut"
            expanded={expanded === "panel4"}
            onChange={handleChange("panel4")}
          >
            <AccordionSummary
              className="acor-summ"
              expandIcon={<ExpandMoreIcon className="acor-ico" />}
              aria-controls="panel4bh-content"
              id="panel4bh-header"
            >
              <Typography
                className="acor-titl"
                sx={{ flexShrink: 0 }}
                component="span"
              >
                Is there a minimum pricing requirement for counselling sessions?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="footer-link" component="span">
                Yes, each session must be priced above 499 to ensure fair
                compensation for the counsellor's expertise.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            className="acor-abut"
            expanded={expanded === "panel5"}
            onChange={handleChange("panel5")}
          >
            <AccordionSummary
              className="acor-summ"
              expandIcon={<ExpandMoreIcon className="acor-ico" />}
              aria-controls="panel4bh-content"
              id="panel4bh-header"
            >
              <Typography
                className="acor-titl"
                sx={{ flexShrink: 0 }}
                component="span"
              >
                How do I add funds to my wallet on A2Z?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="footer-link" component="span">
                You can add funds to your wallet by selecting the "Add Funds"
                option in your account settings and choosing your preferred
                online payment method/wallet.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            className="acor-abut"
            expanded={expanded === "panel6"}
            onChange={handleChange("panel6")}
          >
            <AccordionSummary
              className="acor-summ"
              expandIcon={<ExpandMoreIcon className="acor-ico" />}
              aria-controls="panel4bh-content"
              id="panel4bh-header"
            >
              <Typography
                className="acor-titl"
                sx={{ flexShrink: 0 }}
                component="span"
              >
                Can I change the price of my counselling sessions after posting
                them?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="footer-link" component="span">
                Yes, once a session is posted, the price can be changed using
                the Edit button in session detail. Consider pricing carefully
                before posting your sessions.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            className="acor-abut"
            expanded={expanded === "panel7"}
            onChange={handleChange("panel7")}
          >
            <AccordionSummary
              className="acor-summ"
              expandIcon={<ExpandMoreIcon className="acor-ico" />}
              aria-controls="panel4bh-content"
              id="panel4bh-header"
            >
              <Typography
                className="acor-titl"
                sx={{ flexShrink: 0 }}
                component="span"
              >
                Is there a limit to the number of counselling sessions a
                counselee can bid on?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="footer-link" component="span">
                No, there is no specific limit. counselees can bid on multiple
                sessions based on their preferences and needs.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            className="acor-abut"
            expanded={expanded === "panel8"}
            onChange={handleChange("panel8")}
          >
            <AccordionSummary
              className="acor-summ"
              expandIcon={<ExpandMoreIcon className="acor-ico" />}
              aria-controls="panel4bh-content"
              id="panel4bh-header"
            >
              <Typography
                className="acor-titl"
                sx={{ flexShrink: 0 }}
                component="span"
              >
                How can I explore new counselling sessions on A2Z?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="footer-link" component="span">
                Navigate to the "Explore Sessions" section to discover available
                counselling sessions. Use filters to find sessions that match
                your preferences and needs.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </div>
      </>
    </div>
  );
};

export default AboutSession;
