import React, { useEffect, useState, createContext } from "react";
import axios from "axios";
import { API } from "../api-config";
import Loader from "../components/Loader/Loader";
import socket from "../socket/socketio";
const SessionContext = createContext();

export function SessionContextProvider({ children }) {
  const [session, setSession] = useState("");
  const [totalLength, setTotalLength] = useState(0);
  async function fetchData() {
    await axios
      .get(`${API}/api/session/get-session`, {
        headers: { len: 8, offset: 0 },
      })
      .then((res) => {
        setTotalLength(res.headers.counting);
        setSession(res.data);
      });
  }
  useEffect(() => {
    try {
      socket.on("newSession", () => {
        fetchData();
      });
      fetchData();
      return () => {
        socket.off("newSession");
      };
    } catch (error) {
      console.log(error);
    }
  }, [socket]);

  if (!session) return <Loader />;

  return (
    <SessionContext.Provider
      value={{
        session,
        totalLength,
        setSession,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
}

export default SessionContext;
