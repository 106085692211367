import React from "react";

const VerifyFirst = () => {
  return (
    <div
      className="d-flex flex-column justify-content-center align-items-center"
      style={{ height: "60vh" }}
    >
      <h1 className="d-block">Thank you for registering at A2ZCounsel</h1>
      <h5>Please check email and verify your account</h5>
    </div>
  );
};

export default VerifyFirst;
