import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import banner from "../../images/bg1.png";
import { FiSearch } from "react-icons/fi";
import "../../style/partials/components/Silder.scss";
import axios from "axios";
import { API } from "../../api-config";
import UserContext from "../../contexts/UserContext";

function Silder() {
  const { userData, setUserData } = useContext(UserContext);
  const [reac, setReac] = useState(true);
  const [lists, setLists] = useState([]);
  const navigate = useNavigate();
  const searchTyping = () => {
    const results = document.getElementById("resulting");
    results.classList.toggle("display");
  };
  const [skills, setSkills] = useState(null);
  async function fetchData() {
    try {
      const { data } = await axios.get(`${API}/api/skillslist/list-skills`, {});
      setSkills(data);
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    fetchData();
  }, []);
  const skillsFilter = () => {
    //console.log(skills);
    const inps = document.getElementById("keyseacrh");
    if (inps.value.trim() !== "") {
      const ltrd = skills.filter((skill) =>
        skill.skills.includes(`${inps.value.toUpperCase()}`)
      );
      setLists(ltrd);
    }
  };
  const getCounsellors = (item) => {
    const skill = item;
    navigate(`/search?q=${encodeURIComponent(skill)}`);
    //navigate(`/Session-Result`, { state: skill });
  };
  const getKey = () => {
    const inps = document.getElementById("keyseacrh");
    if (!inps.value) {
      return;
    }
    const skill = inps.value;
    navigate(`/search?q=${encodeURIComponent(skill)}`);
    //navigate(`/Session-Result`, { state: skill });
  };
  return (
    <section className="section">
      <div className="slider-cover-content">
        <div className="inn-txt container">
          <h2>
            Select the right <i>Counsellor</i>
            <br />
            Follow the correct roadmap with Us
          </h2>

          {reac && userData?.flag != "COUNSELLOR" && (
            <div className="search-box">
              <input
                type="search"
                id="keyseacrh"
                name="keysearch"
                onClick={searchTyping}
                onChange={skillsFilter}
                onKeyPress={(e) => {
                  if (e.key == "Enter" && e.target.value.trim() != "") {
                    getKey();
                  }
                }}
                placeholder="Start your search for the guidance.."
              />
              <FiSearch className="search-icon cursor-pointer" onClick={getKey} />
              <div className="searchResults" id="resulting">
                {lists &&
                  lists.map((item, index) => (
                    <button key={`${item.skills}${index}`}
                      onClick={() => {
                        getCounsellors(item.skills);
                      }}
                    >
                      {item.skills}
                    </button>
                  ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
}

export default Silder;
