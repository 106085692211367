import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { API } from "../../../api-config.js";
import axios from "axios";
import UserContext from "../../../contexts/UserContext";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";
import SectionsHead from "../../Home/Heading.js";
import Button from "../../Elements/Button.js";


let offset = 1;
let wait = false;
const RejectedSessions = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const [state, setstate] = useState([]);
  const [userType, setUserType] = useState('');
  const [pagesCount, setPagesCount] = useState(0);
  const navigate = useNavigate();
  const { userData } = useContext(UserContext);

  const rejectedList = async () => {
    let entity = userData.flag === "STUDENT" ? 'student' : 'counsellor';
    setUserType(entity);
    const response = await axios.get(`${API}/api/bidrequest/rejected-bid`, {
      headers: {
        userId: userData.userId,
        entity: entity,
        offset: offset,
        token: userData.token,
      },
    });
    setstate(response.data.data);
    setPagesCount(Math.ceil(response.data.count / 12));
  };

  useEffect(() => {
    try {
      if (!userData.isProfile) {
        navigate("/counsellor");
      }
      rejectedList();
    } catch (error) {
      console.log(error);
    }
  }, []);

  const pageNum = (num) => {
    offset = num;
    rejectedList();
  };

  const accomodatePage = (clickedPage) => {
    if (clickedPage <= 1) {
      return clickedPage + 1;
    }
    if (clickedPage >= pagesCount) {
      return clickedPage - 1;
    }
    return clickedPage;
  };

  return (
    <>
      <div className="completed-session container text-center mt-5 mb-5">
        <SectionsHead heading="Rejected Sessions" />
        {state.length > 0 ? (
          <div className="row">
            {state.map((item, index) => {
              return (
                <div key={index}  className="col-xl-4 col-lg-4 col-md-6">
                  <div className="row m-2 sec-pos post-counselling">
                      <div className="col-8 pt-2">
                        <h5 key={item._id} className="pb-1">
                          {item.sessionid.sessiontitle.charAt(0).toUpperCase() +
                            item.sessionid.sessiontitle.slice(1)}
                        </h5>
                        {userType != 'student' && (
                          <p className="pb-2">
                            {item.userId.fName}  {item.userId.lName}
                          </p>
                        )}
                        {userType == 'student' && (
                        <p className="pb-2">
                          {item.counselorid.fName}  {item.counselorid.lName}
                        </p>
                        )}
                        <p>
                          {new Date(item.createdAt).toString().slice(4, 15)}
                        </p>
                      </div>
                      <div className="col-4   align-self-center">
                        <p key={`rej${index}`} className="waiting-text">{item.bidAmount} {item.sessionid.defaultCurrency}</p>
                      </div>

                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <h6>No Session Available</h6>
        )}
        {pagesCount > 1 && (
          <div className="a2zpagination">
            {offset > 1 && (
              <button
                className="index-btn mb-2 mt-2"
                onClick={() => {
                  pageNum(offset - 1 >= 1 ? offset - 1 : 1);
                }}
              >
                {<i className="fa-solid fa-chevron-left"></i>}
              </button>
            )}
            {(() => {
              const buttons = [];

              if (pagesCount >= 3) {
                const currentPageNum = accomodatePage(offset);
                for (let i = -1; i < 2; i++) {
                  const pageNumToShow = currentPageNum + i;
                  buttons.push(
                    <button
                      key={pageNumToShow}
                      className={`index-btn mb-2 mt-2 ${pageNumToShow === offset ? 'active' : ''}`}
                      onClick={() => {
                        pageNum(currentPageNum + i);
                      }}
                    >
                      {currentPageNum + i}
                    </button>
                  );
                }
              } else {
                for (let i = 0; i < pagesCount; i++) {
                  const pageNumToShow = 1 + i;
                  buttons.push(
                    <button
                      key={pageNumToShow}
                      className={`index-btn mb-2 mt-2 ${pageNumToShow === offset ? 'active' : ''}`}
                      onClick={() => {
                        pageNum(1 + i);
                      }}
                    >
                      {1 + i}
                    </button>
                  );
                }
              }
              return buttons;
            })()}
            {offset != pagesCount && (
              <button
                className="index-btn mb-2 mt-2"
                onClick={() => {
                  pageNum(offset + 1 <= pagesCount ? offset + 1 : pagesCount);
                }}
              >
                {<i className="fa-solid fa-chevron-right"></i>}
              </button>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default React.memo(RejectedSessions);
