import React, { useEffect, useState } from "react";
import SectionsHead from "../Home/Heading";
import axios from "axios";
import { API } from "../../api-config";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { useNavigate } from "react-router-dom";
import imgg from "../../images/a2z-bg.jpg";
import "../../style/partials/Students/Home.scss";

const Topskills = () => {
  const [skills, setSkills] = useState();
  const navigate = useNavigate();
  const loadPopularSkills = async () => {
    const { data } = await axios.get(`${API}/api/skills/top-skills`);
    setSkills(data.allSkills);
  };

  const skillsSessions = (skill) => {
    navigate("/popular-skill-sessions", {
      state: { entity: "skill", skill: skill },
    });
  };
  useEffect(() => {
    loadPopularSkills();
  }, []);
  return (
    <div className="container mt-5 mb-5">
      <SectionsHead heading="Top Skills" />
      <div className="row pt-3 pb-3">
        {skills &&
          skills.map((item, index) => (
            <div key={`${item._id}${index}`} className="col-lg-3 col-md-6 col-sm-12 mb-2">
              <Card className="top-skills-card shadow">
                <div className="top-skill-image-div">
                  <Card.Img variant="top" src={imgg} />
                </div>
                <Card.Body>
                  <Card.Title className="tittel">{item.skill}</Card.Title>
                  <Card.Text>
                    {item.skill} is a popular skill with most number of
                    bookings.
                  </Card.Text>
                  <Button className="session-btn"
                    onClick={() => {
                      skillsSessions(item.skill);
                    }}
                    variant="dark"
                  >
                    Check Available Session
                  </Button>
                </Card.Body>
              </Card>
            </div>
          ))}
      </div>
    </div>
  );
};
export default Topskills;
