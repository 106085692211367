/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useContext, useEffect, useRef } from "react";
import axios from "axios";
import { HiMenu } from "react-icons/hi";
import { FiLogOut, FiSearch } from "react-icons/fi";
import { CgProfile } from "react-icons/cg";
import { AiOutlineClose } from "react-icons/ai";
import { Dropdown } from "react-bootstrap";
import { IoMdNotificationsOutline } from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../Header/Logo";
import "..//../..//style/partials/components/_header.scss";
import Studentmenu from "../..//../data/Studentmenu";
import UserContext from "../../../contexts/UserContext";
import { API } from "../../../api-config";
import socket from "../../../socket/socketio";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";

let data = [];

function Studentnav() {
  let notice = "";
  let oneEffect = true;
  const { userData, setUserData } = useContext(UserContext);
  const [notifications, setNotifications] = useState([]);
  const [activePro, setActiveProf] = useState(false);
  const [notificationCount, setNotificationCount] = useState(0);
  const navigate = useNavigate();
  const [active, setActive] = useState(false);

  let notiRef = useRef();
  let notiBtn = useRef();
  // console.log(userData);
  useEffect(() => {
    const noti = document.querySelector(".noti");
    let handler = (e) => {
      if (
        !notiRef.current.contains(e.target) &&
        !notiBtn.current.contains(e.target)
      ) {
        noti.classList.remove("display");
      }
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, []);
  const notificationLoad = async () => {
    const response = await axios.post(
      `${API}/api/notifications/getNotification`,
      {
        userId: userData.userId,
        limit: 15,
        offset: 0,
      },
      {
        headers: {
          token: userData.token,
        },
      }
    );
    setNotifications(() => {
      const updated = [...response.data.note[0].notification];
      return updated;
    });
    setNotificationCount(0);
    response.data.note[0].notification.map((item) => {
      let i = 0;
      if (item.date > response.data.note[0].lastSeen) {
        setNotificationCount((prevState) => prevState + 1);
        console.log("notificationCount");
      }
    });
  };
  useEffect(() => {
    if (!oneEffect) return;
    oneEffect = false;
    setNotificationCount((prevState) => prevState - prevState);

    if (userData.isProfile) {
      notificationLoad();
    }

    socket.on(`notig${userData.userId}`, (msg) => {
      console.log("MSG SOCKET USED FOR", msg, "USER DETAILS", userData);
      setNotificationCount((prevState) => prevState - prevState);
      notificationLoad();
      alertify.success("New Notification");
    });
    //Uncommenting this cleanup causing loss of notifications
    // return () => {
    //   socket.off(`notig${userData.userId}`);
    // };
  }, [socket]);
  const notifiy = (notice) => {
    setNotifications((prevState) => [...prevState, { msg: notice }]);
    console.log(notifications);
  };
  const handleClick = () => {
    if (window.innerWidth > 991) return;
    setActive(!active);
  };
  const handleclose = () => {
    setActive(false);
  };
  const handleExpand = () => {
    const search = document.querySelector(".search-input");
    search.classList.toggle("search-expanded");
  };

  const handleNotification = async () => {
    try {
      // console.log(notifications);
      const noti = document.querySelector(".noti");
      noti.classList.toggle("display");
      setNotificationCount(0);
      const response = await axios.post(
        `${API}/api/notifications/seenNotification`,

        { userId: userData.userId },
        {
          headers: {
            token: userData.token,
          },
        }
      );
      // setNotificationCount(0);
    } catch (error) {
      console.log("ERROR", error);
    }
  };
  const LogOut = () => {
    localStorage.removeItem("userData");
    localStorage.clear("userData");
    setUserData(null);
    navigate("/login");
    console.log("navigated");
  };
  // const closeNotification = async () => {
  //   try {
  //     console.log(document.querySelector(".noti").classList);
  //     if (document.querySelector(".noti").classList === "noti") return;
  //     const noti = document.querySelector(".noti");
  //     noti.classList.toggle("display");
  //   } catch (error) {
  //     console.log("ERROR", error);
  //   }
  // };
  // window.addEventListener("onClick", closeNotification);
  return (
    <>
      <nav className="navbar container-fluid">
        <div className="col-lg-11 col-md-10 col-sm-10 col-9 d-flex-costomize">
          <div
            className="menu-icon col-lg-11 col-md-6 col-6"
            onClick={handleClick}
          >
            <div className="menu-list pl-3 nav-link-costomize-counsellors">
              {Studentmenu.map((item, index) => {
                return (
                  <Link
                    key={index}
                    to={item.url}
                    className={item.cName}
                    onClick={handleclose}
                  >
                    {item.title}
                  </Link>
                );
              })}
            </div>
            <i className={active ? "fas fa-times" : "fas fa-bars"}></i>
          </div>
          <Logo />
          <ul className={active ? "nav-menu active" : "nav-menu"}>
            <li className="header-logo-sticky">
              <h3>A2Z</h3>
              <Link to="#" onClick={handleclose}>
                <AiOutlineClose />
              </Link>
            </li>

            {Studentmenu.map((item, index) => {
              return (
                <li key={index}>
                  <Link
                    to={item.url}
                    className={item.cName}
                    onClick={handleclose}
                  >
                    {item.title}
                  </Link>
                </li>
              );
            })}
            {/* <li>
              <Sub />
            </li> */}
          </ul>
        </div>
        <div className=" col-lg-1 col-md-2 col-sm-2 col-3 hd-flex student">
          <div className="dropdown " ref={notiBtn} onClick={handleNotification}>
            <button className="dropbtn noti-x2">
              <IoMdNotificationsOutline />
              {notificationCount > 0 && (
                <span className="noti-num">{notificationCount}</span>
              )}
            </button>
          </div>
          <Dropdown>
            <Dropdown.Toggle className="admin-icon ">
              <CgProfile />
            </Dropdown.Toggle>
            <Dropdown.Menu className="drop-scroll-costmaize open-dropdwon-box-admin-icon">
              {/* <div style={{ position: "absolute", zIndex: "999" }}> */}
              <Dropdown.Item>
                <div className="dropdown col-md-2" key="mdd11">
                  <button
                    onClick={() => {
                      navigate("/student-profile");
                    }}
                  >
                    My Profile
                  </button>
                </div>
              </Dropdown.Item>
              <Dropdown.Item>
                <div className="dropdown col-md-2" key="mdd11">
                  <button className="dropbtn" onClick={LogOut}>
                    Logout
                    <FiLogOut />
                  </button>
                </div>
              </Dropdown.Item>
              {/* </div> */}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        {/* <Button>SIGN UP</Button> */}
        <div className="noti" ref={notiRef}>
          {notifications.length > 0 ? (
            notifications.map((item, index) => <p key={index}>{item.msg}</p>)
          ) : (
            <p>All Caught Up</p>
          )}
          {notifications.length > 0 && (
            <div className="det-button mt-3">
              <button
                type="button"
                className="btn btn-outline-primary custom-btn btn-8"
                onClick={() => {
                  navigate("/notifications");
                }}
              >
                See More
              </button>
            </div>
          )}
        </div>
      </nav>
      {/* notification for students will be diaplayed here */}
    </>
  );
}
export default Studentnav;
