import React, { useState, useEffect } from "react";
import axios from "axios";
import { API } from "../../api-config";
import SectionsHead from "../Home/Heading";
import "../../style/partials/components/_reviewsAll.scss";
import profile1 from "../../images/man (2)@3x.png";
import { Rating } from "react-simple-star-rating";

const Reviews = () => {
  const [reviewsList, setReviewsList] = useState([]);
  const getTopReviews = async () => {
    const topReviews = await axios.get(`${API}/api/feedback/all-reviews`);
    setReviewsList(topReviews.data.allFeedBackss[0].feedbacks);
  };
  useEffect(() => {
    getTopReviews();
  }, []);
  return (
    <div className="mt-5 mb-5">
      <SectionsHead heading="Counselee Reviews" />
      <div className="fluid"></div>
      <section className="reviews-section-teacher">
        <div className="container">
          <div className="col-lg-10">
            <p>
              Our journey to becoming the premier online counselling platform at
              global is attributed to our high-quality session content, the
              unique approach of our diverse counsellors, and our meticulous
              client feedback system. After each session, counselees provide a
              rating (1 to 5, with 5 being the highest) and can also share
              written feedback. This continuous feedback system enables our
              clients to evaluate our team of experienced counsellors and helps
              them identify the best counsellor who matches their personality
              and therapeutic needs.
            </p>
          </div>
        </div>
      </section>
      <div className="container">
        <div className="row">
          {reviewsList &&
            reviewsList.map((item, index) => {
             
              return (
                <div key={index} className="col-xl-3 col-lg-4 col-md-6 mb-4">
                  <div className="reviews-sec-card h-100 shadow">
                    <div className="row">
                      <div className="col-12 d-flex">
                        <div className="img-titel-div2 col-12">
                          <img
                            src={
                              item?.studentdetails?.profilePic
                                ? item?.studentdetails?.profilePic
                                : profile1
                            }
                            className=" col-3 card-img prof-img"
                            alt="profilePic"
                            loading="lazy"
                            height="60"
                            width="60"
                          />
                          <div className="col-9 name-ditels">
                            <p>
                              <span className="teacher-name">
                                {item && item.counsellorId.fName}
                              </span>
                            </p>
                            <div className="star-reating">
                              <Rating
                                className=""
                                readonly={true}
                                initialValue={item.counsellorRating}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-body body-summery">
                      <p className="card-text">{`${item.feedbackDesc}`}</p>
                    </div>
                    <hr className="custom-hr" />
                    <div className="d-flex justify-content-between m-2">
                      <span>
                        <b>{item.studentId.fName}</b>
                      </span>
                      <span>
                        {new Date(item.createdAt).toDateString().slice(4, 15)}
                      </span>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default React.memo(Reviews);
