import react, { useEffect, useState } from "react";
import SectionsHead from "../Home/Heading";
import { useLocation } from "react-router-dom";
import { API } from "../../api-config";
import axios from "axios";
import profile from "../../images/test.png";
import { useNavigate } from "react-router-dom";
import "../../style/partials/components/_experts.scss";

const Expert = () => {
  const temp = useLocation();
  const [review, setReviews] = useState([]);
  const [ratingsData, setRatingsData] = useState();
  const [counsellor, setCounsellor] = useState("");
  const [photo, setPhoto] = useState(null);
  const navigate = useNavigate();
  console.log("EXPeRT ", temp);
  const getReviews = async () => {
    const reviews = await axios.get(`${API}/api/feedback/counsellor-feedback`, {
      headers: { counsellorId: temp.state.counsellorId._id },
    });
    console.log("counsellor", reviews);
    // const counsellor = await axios.get(
    //   `${API}/api/counselor-profile/getCounselor`,
    //   {
    //     params: { id: temp.state.counsellorId._id },
    //   }
    // // );
    console.log("counsellor", temp);
    const userResponse = await axios.post(
      `${API}/api/counselor-profile/get-counselor-skill`,
      { userId: temp.state.counsellorId._id }
    );
    console.log(userResponse, counsellor);
    setPhoto((prevState) => userResponse.data.profilePic[0]);
    setCounsellor(userResponse.data);
    setReviews([...reviews.data]);
    const ratings = await axios.get(`${API}/api/rating/getRating`, {
      headers: {
        counsellorId: temp.state.counsellorId._id,
      },
    });
    setRatingsData(ratings.data);
  };
  useEffect(() => {
    getReviews();
  }, []);
  return (
    <div className="container">
      <div className="row reviews-body">
        <SectionsHead heading="Our Experts" />
        <div className="col-md-12">
          <div className="card-body experts shadow">
            <div className="our-expert-page-div">
              <div className="col-lg-6 col-md-7 col-sm-12">
                <div className="img-content-div">
                  <img
                    src={photo ? photo : profile}
                    className=" card-img prof-img"
                    alt="Profile Pic"
                    loading="lazy"
                  />
                  <div className="id-owner-name-div">
                    <h5>{temp.state.counsellorId.fName}</h5>
                    {counsellor &&
                      counsellor?.skills?.map((item, index) => (
                        <span>{`${item.skill}  , `}</span>
                      ))}
                  </div>
                </div>
                <div className="full-discribtion">
                  <p>
                    {`${counsellor.description}`} and has helped many students
                    in improving their English speaking and soft skills. She is
                    a certified IELTS trainer. She has also assisted students in
                    preparing for interviews, public speaking and email writing
                    skills.
                  </p>

                  <span>
                    <i className="fa-solid fa-clock"></i>{" "}
                    {counsellor && `${counsellor.city}`}
                  </span>
                  <span>
                    <i className="fa-solid fa-earth-americas"></i>{" "}
                    {counsellor && `${counsellor.country}`}
                  </span>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-12 border-top">
                <div className="dittel-lessons">
                  <span>{ratingsData && ratingsData.totalsessions}</span>
                  <i className="fa-solid fa-person-chalkboard"></i>
                  <span className="icon-titel">Ratings</span>
                </div>
                <div className="dittel-ratings">
                  <span>{ratingsData && ratingsData.ratingstar}</span>
                  <i className="fa-solid fa-star"></i>
                  <span className="icon-titel"> Avg. Rating</span>
                </div>
                <div>
                  <button
                    className="dittel-ratings"
                    onClick={() => {
                      navigate("/popular-skill-sessions", {
                        state: {
                          entity: "counsellor",
                          counsellor: temp.state.counsellorId._id,
                        },
                      });
                    }}
                  >
                    Book Sessions
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="row row-cols-1 row-cols-md-2 g-4 reviews">
            {review.length > 0 ? (
              review?.map((item, index) => (
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 mb-3">
                  <div className="card h-100 shadow">
                    <div className="row">
                      <div className="col-12 d-flex">
                        <div className="img-titel-div2 col-12">
                          <img
                            src={profile}
                            className=" col-3 card-img prof-img"
                            alt="profile"
                            loading="lazy"
                          />

                          <div className="col-9 name-ditels">
                            <p>{`Counselee : ${
                              item.studentId?.fName.charAt(0).toUpperCase() +
                              item.studentId.fName.slice(1)
                            }`}</p>
                            <p>{item?.sessionid?.sessionid?.skills?.skill}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-body body-summery">
                      <p className="card-text">
                        <b>Experience : </b>
                        {item.feedbackDesc && ` ${item.feedbackDesc}. `}
                        <br />
                        This is a longer card with supporting text below as a
                        natural lead-in to additional content. This content is a
                        little bit longer.
                      </p>
                    </div>
                    {/* <div className="card-footer">
                      <small className="text-muted ms-2">
                        {new Date(item.createdAt).toDateString().slice(4)}
                      </small>
                    </div> */}
                  </div>
                </div>
              ))
            ) : (
              <p>No Counsellors With This Skillset</p>
            )}
          </div>
        </div>
        {/* <div>
          <button>Show More</button>
        </div> */}
      </div>
    </div>
  );
};
export default Expert;
