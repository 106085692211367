const counsellormenu = [
  {
    title: "Add New Counselling",
    url: "/select",
    cName: "nav-links",
  },
  {
    title: "My Sessions",
    url: "/counsellor",
    cName: "nav-links",
  },
  {
    title: "Upcoming Sessions",
    url: "/counsellor-upcoming-session",
    cName: "nav-links",
  },
  {
    title: "Completed Sessions",
    url: "/counsellor-completed-session",
    cName: "nav-links",
  },
  {
    title: "Rejected Sessions",
    url: "/rejected-session",
    cName: "nav-links",
  },
  {
    title: "Reviews",
    url: "/reviews",
    cName: "nav-links",
  },
  // {
  //   title: "FAQs",
  //   url: "/faqs",
  //   cName: "nav-links",
  // },
  {
    title: "About US",
    url: "/about-us",
    cName: "nav-links",
  },
];

export default counsellormenu;
