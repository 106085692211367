import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import { UserContextProvider } from "./contexts/UserContext";
import { CounsellorContextProvider } from "./api/apicounselllor";
import { SessionContextProvider } from "./api/apistudentprofile";
import { SkillsContextProvider } from "./api/apiskills";
import { Categoriesapi } from "./api/categoriesapi";

import { Reviews } from "./api/apireviews";

import "./App.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
    <UserContextProvider>
      <CounsellorContextProvider>
        <SkillsContextProvider>
          <SessionContextProvider>
            <Categoriesapi>
              <Reviews>
                <App />
              </Reviews>
            </Categoriesapi>
          </SessionContextProvider>
        </SkillsContextProvider>
      </CounsellorContextProvider>
    </UserContextProvider>
  // </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
