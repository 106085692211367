import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import UserContext from "../../../contexts/UserContext";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { API } from "../../../api-config";
import useRazorpay from "react-razorpay";

function Paymentscreen() {
    const [paymentType, setPaymentType] = useState('');
    const [amount, setAmount] = useState('');
    const [currency, setCurrency] = useState('');
    const [userType, setUserType] = useState('');
    const { userData } = useContext(UserContext);
    const location = useLocation();
    const Navigate = useNavigate();
    const [Razorpay] = useRazorpay();
    const paymentTypes = ['paypal', 'razorpay', 'stripe', 'paytm', 'payu'];

    useEffect(() => {
        if (location.state) {
            setCurrency(location.state.currency);
            setAmount(location.state.amount);
            setUserType(location.state.userType);
        }
    }, [location.state]);

    const handlePaymentTypeChange = (event) => {
        setPaymentType(event.target.value);
    };

    const handlePayment = async () => {
        if (paymentType) {
            if (paymentType === 'razorpay') {
                try {
                    if (amount > 0 && currency !== "") {
                        let paymentDetails = await axios.post(
                            `${API}/api/wallet/get-orderId`,
                            { amount: amount, currency: currency },
                            {
                                headers: {
                                    userId: userData.userId,
                                    entity: "counsellor",
                                    token: userData.token,
                                },
                            }
                        );

                        var options = {
                            key: process.env.RAZORPAY_KEY_ID,
                            amount: amount,
                            order_id: paymentDetails.data.order.id,
                            currency: currency,
                            name: "Ooze IT Solutions Pvt Ltd",
                            handler: async function (paymentDetails) {
                                try {
                                    const addTransaction = await axios.post(
                                        `${API}/api/transaction/add-transaction`,
                                        {
                                            data: {
                                                amount: amount,
                                                userId: userData.userId,
                                                currency: currency,
                                                bidAmount: amount,
                                                transactionType: "CREDIT",
                                                transactionVia: "Razorpay",
                                            },
                                        },
                                        { headers: { token: userData.token } }
                                    );
                                    if (addTransaction?.data?.userId) {
                                        const walletUpdated = await axios.post(
                                            `${API}/api/wallet/addWalletAmountFromRazorpay`,
                                            { userId: userData.userId, amount: amount, currency: currency },
                                            { headers: { token: userData.token } }
                                        );
                                        if (walletUpdated.status === 200) {
                                            setCurrency("");
                                            setAmount(0);
                                            if(userType == 'cousellor'){
                                                Navigate('/counsellor-profile', { state: { show: false, successnotification: "Amount successfully added to your wallet!" } });
                                            }else{
                                                Navigate('/student-profile', { state: { show: false, successnotification: "Amount successfully added to your wallet!" } });
                                            }
                                            
                                        }
                                    }
                                } catch (error) {
                                    console.log(error);
                                }
                            },
                        };
                        const rzp1 = new Razorpay(options);
                        rzp1.open();
                    }
                } catch (error) {
                    console.log(error);
                }
            }
        } else {
            alert('Please select a payment type');
        }
    };

    const capitalize = (word) => word.charAt(0).toUpperCase() + word.slice(1);

    return (
        <>
            <div className="container d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
                <div className="wallet-card p-4 text-light">
                    <h3 className="mb-4">Payment Gateway Modes</h3>

                    {paymentTypes.map((type) => (
                        <div className="mb-3 d-flex justify-content-between align-items-center" key={type}>
                            {/* Left: Payment Type Radio Button */}
                            <label
                                className="gateway-list d-flex align-items-center"
                                style={{ cursor: 'pointer', width: '50%' }}
                            >
                                <input
                                    type="radio"
                                    value={type}
                                    name="payment_type"
                                    checked={paymentType === type}
                                    onChange={handlePaymentTypeChange}
                                    style={{ marginRight: '10px', cursor: 'pointer' }}
                                />
                                {capitalize(type)}
                            </label>

                            {/* Right: Display the Amount and Currency */}
                            
                        </div>
                    ))}

                    {/* Razorpay 2% charge note */}
                    {paymentType === 'razorpay' && (
                        <div className="mt-3 text-light">
                            <p className="text-warning">Note: A 2% charge will be applied for Razorpay payments.</p>
                        </div>
                    )}
                    <div className="d-flex justify-content-between align-items-end">
                        <button
                            className="btn btn-primary mt-3"
                            onClick={handlePayment}
                        >
                            Proceed with Payment
                        </button>
                        <div style={{fontSize: '1.5rem', fontWeight: 'bold'}}>
                            {currency} {amount} 
                        </div>
                    </div>
                  </div>
            </div>
        </>
    );
}

export default Paymentscreen;
