import React, { useState, useRef, useContext, useEffect } from "react";
import { Modal } from "react-bootstrap";
import SectionsHead from "../../Home/Heading";
import { API } from "../../../api-config";
import axios from "axios";
import useOnClickOutside from "../../../hooks/use-onclick-outside";
// import { throttle } from "lodash";
import { ImUserPlus } from "react-icons/im";
import { useNavigate } from "react-router-dom";
import "../../../style/partials/Students/_userpro.scss";
import UserContext from "../../../contexts/UserContext";
import { useForm } from "react-hook-form";

let wait = false;
function UserProfile() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [state, setstate] = useState([]);
  const [country, setcountry] = useState([]);
  const [stu_address, setstu_address] = useState("");
  // const [stu_desr, setstu_desr] = useState("");
  const [docfile, setdocfile] = useState("");
  const [countryid, setcountryid] = useState("");
  const [statename, setstatename] = useState("");
  const [photo123, setphoto123] = useState(null);
  const [isDisabled, setDisabled] = useState(false);
  const [education, setEducation] = useState("");
  const [docs, setDocs] = useState(null);
  const [show, setShow] = useState(true); // trigger
  //
  const { userData, setUserData } = useContext(UserContext);

  const handleInputChange = (event) => {
    const file = event.target.files[0];
    if (file.size > 2 * 1024 * 1024) {
      alert("File size should be less than 2MB");
    } else {
      setphoto123([event.target.files[0]]);
    }
  };
  const handleDocsChange = (event) => {
    const file = event.target.files[0];
    if (file.size > 2 * 1024 * 1024) {
      alert("File size should be less than 2MB");
    } else {
      setDocs([event.target.files[0]]);
    }
  };
  const ref = useRef();
  // useOnClickOutside(ref, () => setShow(true));
  const navigate = useNavigate();
  const userid = userData.userId;

  const uploadChunk = async (
    file,
    chunk,
    chunkIndex,
    context,
    uploadId,
    partNumber,
    parts
  ) => {
    console.log(parts);
    const formData = new FormData();
    console.log("CHUNK", chunk);
    formData.append("file", chunk);
    formData.append("fileName", file.name);
    formData.append("chunkIndex", chunkIndex);
    formData.append("context", context);
    formData.append("uploadId", uploadId);
    formData.append("partNumber", partNumber);
    formData.append("parts", JSON.stringify(parts));
    console.log(formData);
    try {
      const response = await axios.post(
        `${API}/api/user-profile/uploadChunk`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            token: userData.token,
          },
        }
      );
      console.log(response.data);
      return response.data.parts;
    } catch (error) {
      console.error("Error uploading chunk:", error);
    }
  };
  const uploadFiles = async () => {
    const context = ["profilePic", "proofdoc"];
    const files = [photo123, docs];
    const CHUNK_SIZE = 1024 * 1024;

    files.forEach(async (item, index) => {
      item.forEach(async (file) => {
        let keyName = `/${userData.userId}/${Date().now}-${context[index]}/${
          file.name
        }`;
        console.log(index, item, file, keyName);
        const responseInitiation = await axios.post(
          `${API}/api/user-profile/initiatemultipart`,
          { keyName: keyName },
          {
            headers: {
              token: userData.token,
            },
          }
        );
        const uploadId = responseInitiation.data.uploadId;
        let parts = [];
        const totalChunks = Math.ceil(file.size / CHUNK_SIZE);
        console.log(totalChunks);
        for (let i = 0; i < totalChunks; i++) {
          const start = i * CHUNK_SIZE;
          const end = Math.min(start + CHUNK_SIZE, file.size);
          const chunk = file.slice(start, end);
          const response = await uploadChunk(
            file,
            chunk,
            i,
            keyName,
            uploadId,
            i + 1,
            parts
          );
          parts = response;
        }
        console.log("ONe FILE UPLOADED");
        await axios.post(
          `${API}/api/user-profile/upload-complete`,
          {
            keyName: keyName,
            uploadId: uploadId,
            parts: parts,
            fileName: file.name,
            context: context[index],
          },
          {
            headers: {
              token: userData.token,
            },
          }
        );
      });
    });
  };

  const submitData = (data) => {
    setDisabled(true);
    data.userId = userid;
    data.country = countryid;
    data.state = statename;
    data.address = stu_address;
    data.Education = document.getElementById("education").value;
    setShow(false);
    axios
      .post(`${API}/api/user-profile/create-profile`, data)
      .then((response) => {
        try {
          axios
            .put(`${API}/api/frontregi/update/${userid}`, {
              isProfile: true,
            })
            .then((res) => {
              userData.isProfile = true;
              setUserData(userData);
              localStorage.setItem("userData", JSON.stringify(userData));
              axios
                .get(`${API}/api/currency/getCurrency/${countryid}`)
                .then((res) => {
                  console.log("RREESS ", res);
                  axios
                    .post(`${API}/api/frontregi/defaultCurrency`, {
                      userId: userData.userId,
                      currency: res.data[0].currency,
                    })
                    .then((res) => {
                      console.log("RESPONSE PROFILE", res);
                      userData.defaultCurrency = res.data.defaultCurrency;
                      setUserData(userData);
                      console.log(res);
                      uploadFiles();
                      navigate("/");
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                });
            });
        } catch (error) {
          console.log(error);
        }
      });
  };

  useEffect(() => {
    const getcountry = async () => {
      let myData = await fetch(`${API}/api/country/get-Country`);
      let coutrydata = await myData.json();
      setcountry(await coutrydata);
    };
    getcountry();
    userData.isProfile ? setShow(false) : setShow(true);
  }, []);

  // const throttleSubmitData = throttle(submitData, 2000);
  const handlestate = (event) => {
    const getcountryid = event.target.value;
    console.log(getcountryid, "master");
    setstatename(getcountryid);
  };

  const handlecountry = (event) => {
    const [_id, countryName] = event.target.value.split("_");
    getstate(_id);
    setcountryid(countryName);
  };

  const getstate = async (_id) => {
    let myData = await axios.post(`${API}/api/state/get-statebycountry`, {
      countryId: _id,
    });
    setstate(myData.data);
  };

  return (
    <>
      {!userData.isProfile ? (
        <div ref={ref}>
          {` `}
          <div className="pl-0">
            <section className="studentprofile-pop container">
              <div className="modal-content  tech-resignation" id="modalheader">
                <form
                  onSubmit={handleSubmit((data) => {
                    if (wait) {
                      return;
                    }
                    wait = true;
                    setTimeout(() => {
                      wait = false;
                    }, 5000);
                    submitData(data);
                  })}
                  enctype="multipart/form-data"
                >
                  <div className="row profile-and-ditels">
                    <div className="image-profile-main-div col-lg-4">
                      <label
                        for="photo-upload"
                        className="custom-file-upload fas"
                      >
                        {photo123 ? (
                          <img
                            for="photo-upload"
                            src={photo123}
                            alt="upload"
                            loading="lazy"
                          />
                        ) : (
                          <ImUserPlus className="up-icn" />
                        )}

                        <input
                          id="photo-upload"
                          type="file"
                          accept="image/*"
                          onChange={handleInputChange}
                          name="photo,"
                        />
                      </label>
                    </div>
                    {/* <div className="modal-header mt-1 col-lg-4">
                    <div
                      className="modal-title text-center profile m-auto  "
                      id="exampleModalLabel"
                    >
                      <label
                        for="photo-upload"
                        className="custom-file-upload fas"
                      >
                        <div className="img-wrap img-upload">
                          {photo123 ? (
                            <img
                              for="photo-upload"
                              src={photo123}
                              alt="upload"
                            />
                          ) : (
                            <ImUserPlus className="up-icn" />
                          )}
                        </div>
                        <input
                          id="photo-upload"
                          name="profilePic"
                          type="file"
                          onChange={handleInputChange}
                        />
                      </label>
                    </div>
                  </div> */}
                    <div className="col-lg-6 modal-body-div">
                      <input
                        className="user-name-counsellor"
                        type="text"
                        placeholder="Full Name"
                        value={
                          userData.fName.charAt(0).toUpperCase() +
                          userData.fName.slice(1) +
                          " " +
                          userData.lName.charAt(0).toUpperCase() +
                          userData.lName.slice(1)
                        }
                      />

                      <select
                        className="custom-select"
                        id="education"
                        defaultValue=""
                        name="company"
                        {...register("company", { required: true })}
                      >
                        <option
                          value=""
                          disabled
                          onChange={(e) => {
                            setEducation(e.target.value);
                          }}
                        >
                          Select Education
                        </option>
                        <option value="10th">10th</option>
                        <option value="12th">12th</option>
                        <option value="B.tech">Graduate</option>
                        <option value="M.tech">Post-Graduate </option>
                      </select>
                      {errors.company && (
                        <span className="valid-prof">
                          This field is required
                        </span>
                      )}
                      <input
                        type="number"
                        placeholder="Alternate Contact Number"
                        maxLength={13}
                        onKeyDown={(e) => {
                          console.log(e.key, " ,", e.keyCode);
                          if (
                            e.keyCode == 38 ||
                            e.keyCode == 37 ||
                            e.keyCode == 39 ||
                            e.keyCode == 40
                          ) {
                            console.log("NOT ALLOWED");
                            e.preventDefault();
                          }
                        }}
                        onWheel={(e) => {
                          e.preventDefault();
                        }}
                        {...register("phone1", {
                          required: true,
                          minLength: 10,
                          maxLength: 13,
                          pattern:
                            /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i,
                        })}
                      />
                      {errors?.phone1?.type === "required" && (
                        <p className="valid-prof">This field is required</p>
                      )}
                      {errors?.phone1?.type === "pattern" && (
                        <p className="valid-prof">
                          Insert Valid Contact Number
                        </p>
                      )}
                      {errors?.phone1?.type === "minLength" && (
                        <p className="valid-prof">Insert Valid Contact</p>
                      )}
                      {errors?.phone1?.type === "maxLength" && (
                        <p className="valid-prof">Insert Valid Contact</p>
                      )}
                    </div>
                    <div className="col-md-4">
                      <textarea
                        type="text"
                        {...register("addr", {
                          required: true,
                          maxLength: 100,
                        })}
                        id="stu_address"
                        value={stu_address}
                        onChange={(e) => setstu_address(e.target.value)}
                        placeholder="Address"
                      />
                      {errors?.addr?.type === "required" && (
                        <p className="valid-prof">This field is required</p>
                      )}

                      <select onChange={(e) => handlecountry(e)}>
                        <option defaultValue hidden>
                          {"Select Country"}
                        </option>
                        {country.map((getcon, index) => {
                          return (
                            <>
                              <option
                                key={index}
                                value={
                                  getcon._id + "_" + getcon.countryName + "_"
                                }
                              >
                                {getcon.countryName}
                              </option>
                            </>
                          );
                        })}
                      </select>
                    </div>
                    <div className="col-md-4 modal-body-div">
                      <select onChange={(e) => handlestate(e)}>
                        <option defaultValue hidden>
                          {"Select State"}
                        </option>
                        {state.map((getste, index) => {
                          return (
                            <>
                              <option key={index} value={getste.stateName}>
                                {getste.stateName}
                              </option>
                            </>
                          );
                        })}
                      </select>
                      <input
                        placeholder="City"
                        onKeyPress={(e) => {
                          if (e.target.value === "" && e.key === " ") {
                            e.preventDefault();
                          }
                        }}
                        {...register("city", {
                          required: true,
                          maxLength: 20,
                          pattern: /^[A-Za-z]+$/i,
                        })}
                      />
                      {errors?.city?.type === "required" && (
                        <p className="valid-prof">This field is required</p>
                      )}

                      {errors?.city?.type === "maxLength" && (
                        <p className="valid-prof">City name is too long</p>
                      )}
                    </div>
                    <div className="col-md-4 ">
                      <input
                        type="number"
                        onKeyDown={(e) => {
                          console.log(e.key, " ,", e.keyCode);
                          if (
                            e.keyCode == 38 ||
                            e.keyCode == 37 ||
                            e.keyCode == 39 ||
                            e.keyCode == 40
                          ) {
                            e.preventDefault();
                          }
                        }}
                        onWheel={(e) => {
                          e.preventDefault();
                        }}
                        onKeyPress={(e) => {
                          if (e.target.value === "" && e.key === " ") {
                            e.preventDefault();
                          }
                        }}
                        placeholder="Zip Code"
                        {...register("zip", {
                          required: true,
                          maxLength: 8,
                        })}
                      />
                      {errors?.zip?.type === "required" && (
                        <p className="valid-prof">This field is required</p>
                      )}
                      {errors?.zip?.type === "maxLength" && (
                        <p style={{ color: "red" }}>
                          Enter valid pincode. Max 8 digits allowed
                        </p>
                      )}

                      <div className="upl-btn mb-3">
                        <label>Document Proof</label>
                        <input
                          type="file"
                          id="doument"
                          name="accountDocument"
                          accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                          // {...register("proofdoc", { required: true })}
                          onChange={handleDocsChange}
                        />
                      </div>
                    </div>

                    <div className="modal-body-div "></div>
                  </div>
                  <div className="submit-btn text-center mb-2 mt-2">
                    <button className=" btn btn-submit" disabled={isDisabled}>
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </section>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
export default UserProfile;
