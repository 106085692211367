import { React, useContext, useState, useEffect, Suspense } from "react";
import UserContext from "../../../contexts/UserContext";
import axios from "axios";
import Loader from "../../Loader/Loader";
import profile from "../../../images/test.png";
import { useNavigate } from "react-router-dom";
import { API } from "../../../api-config";
import "../../../style/partials/Counsellor/_counsellorprofile.scss";
import Studentwallet from "../Wallet/Studentwallet";
import AboutCounsel from "../Home/Aboutcounselling";
import { useLocation } from "react-router-dom";
function StudentProfile() {
  const Navigate = useNavigate();
  const { userData } = useContext(UserContext);
  const [data, setdata] = useState([]);
  const [show, setShow] = useState(true);
  const [photo, setPhoto] = useState(null);
  const [successnotification, setSuccessNotification] = useState("");
  const location = useLocation();
  useEffect(() => {
    // Check if state was passed from the previous component
    if (location.state) {
      // Update the state based on the passed value
      setShow(location.state.show);
       setSuccessNotification(location.state.successnotification)
    }
  }, [location.state]); 
  useEffect(() => {
    if (!userData.isProfile) {
      Navigate("/students");
    }
    const token = localStorage.getItem("userData");
    //console.log(userData.token);
    const updateUsers = async () => {
      let myData = await axios.post(
        `${API}/api/user-profile/get-student-profile`,
        {
          userId: userData.userId,
          token: userData.token,
        },
        {
          headers: {
            token: userData.token,
          },
        }
      );
      setdata(myData.data);
      //console.log(myData.data);
      setPhoto((prevState) => myData.data[0].profilePic);
    };
    updateUsers();
  }, [userData.userId]);
  // console.log(data, "check");

  function Edit(id) {
    //console.log(id, "id");
    Navigate("/student-update-profile", { state: id });
  }

  if (data.length === 0) {
    console.log(data);
    return (
      <div>
        <Loader />
      </div>
    );
  }
  return (
    <>
    {successnotification && (
      <div className="container mb-3 mt-3">
        <div className="row">
          <div className="alert alert-success alert-dismissible">
            {" "}
            <a
              onClick={() => {
                setSuccessNotification("");
              }}
              className="close"
              data-dismiss="alert"
              aria-label="close"
            >
              &times;
            </a>
            {successnotification}
          </div>
        </div>
      </div>
    )}
    <section className="container mt-5 mb-5">
      {data.map((x, index) => {
        return (
          <div
            className="container profile-cart p-3 col-lg-12  shadow-sm mt-3"
            key={index}
          >
            <div className="row">
              <div className="col-12 col-md-12 id-card">
                <div className="col-12 col-md-6 id-card-det">
                  <div className="col-4 col-md-4 col-md-3">
                    <div className="profile-cart-img text-center">
                      <img
                        src={x?.profilePic ? x.profilePic : photo}
                        alt="profile pic"
                        loading="lazy"
                        height="60"
                        width="60"
                      />
                    </div>
                  </div>

                  <div className="col-8 col-md-8 align-self-center">
                    <div className="profile-header-detail ">
                      <h6>
                        {x.userId?.fName.charAt(0).toUpperCase() +
                          x.userId.fName.slice(1) +
                          " " +
                          x.userId?.lName.charAt(0).toUpperCase() +
                          x.userId?.lName.slice(1)}
                      </h6>
                      <h6>{x.userId.email}</h6>
                      <h6>{x.userId.mobileNo}</h6>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6 id-card-btns">
                  <div className="col-4 col-md-4 ">
                    <button
                      className="wallet-btn"
                      type="button"
                      onClick={() => setShow(!show)}
                    >
                      Wallet
                    </button>
                  </div>
                  <div className="col-4 col-md-4">
                    <button
                      className="edit-btn"
                      type="button"
                      onClick={() => Edit(x._id)}
                    >
                      Edit Profile
                    </button>
                  </div>
                  <div className="col-4 col-md-4 ">
                    <button
                      className="wallet-btn"
                      type="button"
                      onClick={() => Navigate("/transactions")}
                    >
                      Transactions
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}

      {show ? null : <Studentwallet />}
      <AboutCounsel />
    </section>
    </>
  );
  
}

export default StudentProfile;
