import React from "react";
import SectionsHead from "../Home/Heading";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "../../style/partials/components/_allFaqs.scss";

const AllFaqs = () => {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <div>
      <>

        <div className="container mt-5 mb-5">
        <SectionsHead heading="Frequently Asked Questions" />
          <Accordion
            className="acor-abut"
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
          >
            <AccordionSummary
              className="acor-summ"
              expandIcon={<ExpandMoreIcon className="acor-ico" />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography
                className="acor-titl"
                sx={{ flexShrink: 0 }}
                component="span"
              >
                What is A2Z?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="footer-link" component="span">
                IA2Z is a comprehensive online counselling platform that
                connects counselees with professional counsellors spanning
                various specialities to address diverse personal and
                professional needs.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            className="acor-abut"
            expanded={expanded === "panel2"}
            onChange={handleChange("panel2")}
          >
            <AccordionSummary
              className="acor-summ"
              expandIcon={<ExpandMoreIcon className="acor-ico" />}
              aria-controls="panel2bh-content"
              id="panel2bh-header"
            >
              <Typography
                className="acor-titl"
                sx={{ flexShrink: 0 }}
                component="span"
              >
                What types of counselling sessions are available on A2Z?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="footer-link" component="span">
                A2Z offers a wide range of counselling sessions, covering areas
                such as career growth, financial well-being, IT proficiency,
                personal development, and much more.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            className="acor-abut"
            expanded={expanded === "panel3"}
            onChange={handleChange("panel3")}
          >
            <AccordionSummary
              className="acor-summ"
              expandIcon={<ExpandMoreIcon className="acor-ico" />}
              aria-controls="panel3bh-content"
              id="panel3bh-header"
            >
              <Typography
                className="acor-titl"
                sx={{ flexShrink: 0 }}
                component="span"
              >
                What payment options are available on A2Z?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="footer-link" component="span">
                A2Z supports secure payment options, including debit cards,
                credit cards, net banking, UPI, and wallets, providing
                flexibility and convenience.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            className="acor-abut"
            expanded={expanded === "panel4"}
            onChange={handleChange("panel4")}
          >
            <AccordionSummary
              className="acor-summ"
              expandIcon={<ExpandMoreIcon className="acor-ico" />}
              aria-controls="panel4bh-content"
              id="panel4bh-header"
            >
              <Typography
                className="acor-titl"
                sx={{ flexShrink: 0 }}
                component="span"
              >
                How is user data protected on A2Z?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="footer-link" component="span">
                A2Z employs robust security measures to protect user data,
                utilizing encryption and privacy safeguards to ensure
                confidentiality.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            className="acor-abut"
            expanded={expanded === "panel5"}
            onChange={handleChange("panel5")}
          >
            <AccordionSummary
              className="acor-summ"
              expandIcon={<ExpandMoreIcon className="acor-ico" />}
              aria-controls="panel4bh-content"
              id="panel4bh-header"
            >
              <Typography
                className="acor-titl"
                sx={{ flexShrink: 0 }}
                component="span"
              >
                Are there any additional fees for using A2Z's counselling
                services?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="footer-link" component="span">
                A2Z charges a service fee, which is transparently communicated
                during the payment process. No hidden fees are involved.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            className="acor-abut"
            expanded={expanded === "panel6"}
            onChange={handleChange("panel6")}
          >
            <AccordionSummary
              className="acor-summ"
              expandIcon={<ExpandMoreIcon className="acor-ico" />}
              aria-controls="panel4bh-content"
              id="panel4bh-header"
            >
              <Typography
                className="acor-titl"
                sx={{ flexShrink: 0 }}
                component="span"
              >
                Can I provide feedback on the platform and counselling sessions?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="footer-link" component="span">
                Absolutely! A2Z values users' feedback for continuous
                improvement. Share your insights on the platform's features and
                the counselling session experience.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            className="acor-abut"
            expanded={expanded === "panel7"}
            onChange={handleChange("panel7")}
          >
            <AccordionSummary
              className="acor-summ"
              expandIcon={<ExpandMoreIcon className="acor-ico" />}
              aria-controls="panel4bh-content"
              id="panel4bh-header"
            >
              <Typography
                className="acor-titl"
                sx={{ flexShrink: 0 }}
                component="span"
              >
                Is there a support team available to assist counselees on A2Z?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="footer-link" component="span">
                Yes, A2Z has a dedicated support team to assist counselees with
                any queries or issues they may encounter on the platform. You
                can always reach out to us via the 24x7 chat option available on
                our platform.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            className="acor-abut"
            expanded={expanded === "panel8"}
            onChange={handleChange("panel8")}
          >
            <AccordionSummary
              className="acor-summ"
              expandIcon={<ExpandMoreIcon className="acor-ico" />}
              aria-controls="panel4bh-content"
              id="panel4bh-header"
            >
              <Typography
                className="acor-titl"
                sx={{ flexShrink: 0 }}
                component="span"
              >
                Are Counsellors available at all times?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="footer-link" component="span">
                Yes. We have counsellors available 24/7. So, you can make an
                appointment at any time best suited to your requirements.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <div className="faq-counsellor-hedding">
            <h4>Counsellor</h4>
          </div>
          <Accordion
            className="acor-abut"
            expanded={expanded === "panel9"}
            onChange={handleChange("panel9")}
          >
            <AccordionSummary
              className="acor-summ"
              expandIcon={<ExpandMoreIcon className="acor-ico" />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography
                className="acor-titl"
                sx={{ flexShrink: 0 }}
                component="span"
              >
                How do I post a counselling session on A2Z?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="footer-link" component="span">
                Initiate a new counselling session by providing a title, session
                description, relevant skills, and estimated numbers and prices.
                Ensure each session is priced above rs 499 before posting.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            className="acor-abut"
            expanded={expanded === "panel10"}
            onChange={handleChange("panel10")}
          >
            <AccordionSummary
              className="acor-summ"
              expandIcon={<ExpandMoreIcon className="acor-ico" />}
              aria-controls="panel2bh-content"
              id="panel2bh-header"
            >
              <Typography
                className="acor-titl"
                sx={{ flexShrink: 0 }}
                component="span"
              >
                How do counsellors and counselees schedule sessions on A2Z?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="footer-link" component="span">
                Once a session is accepted, counselors and counselees
                collaborate to schedule session times and dates. Sessions can be
                conducted within our platform.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            className="acor-abut"
            expanded={expanded === "panel11"}
            onChange={handleChange("panel11")}
          >
            <AccordionSummary
              className="acor-summ"
              expandIcon={<ExpandMoreIcon className="acor-ico" />}
              aria-controls="panel3bh-content"
              id="panel3bh-header"
            >
              <Typography
                className="acor-titl"
                sx={{ flexShrink: 0 }}
                component="span"
              >
                How is the money transferred to the counsellor?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="footer-link" component="span">
                After a session is completed, the agreed-upon amount is
                seamlessly transferred to the counsellor's wallet, ensuring a
                smooth and secure transaction.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            className="acor-abut"
            expanded={expanded === "panel12"}
            onChange={handleChange("panel12")}
          >
            <AccordionSummary
              className="acor-summ"
              expandIcon={<ExpandMoreIcon className="acor-ico" />}
              aria-controls="panel4bh-content"
              id="panel4bh-header"
            >
              <Typography
                className="acor-titl"
                sx={{ flexShrink: 0 }}
                component="span"
              >
                Is there a minimum pricing requirement for counselling sessions?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="footer-link" component="span">
                Yes, each session must be priced above 499 to ensure fair
                compensation for the counsellor's expertise.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            className="acor-abut"
            expanded={expanded === "panel13"}
            onChange={handleChange("panel13")}
          >
            <AccordionSummary
              className="acor-summ"
              expandIcon={<ExpandMoreIcon className="acor-ico" />}
              aria-controls="panel4bh-content"
              id="panel4bh-header"
            >
              <Typography
                className="acor-titl"
                sx={{ flexShrink: 0 }}
                component="span"
              >
                How do I add funds to my wallet on A2Z?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="footer-link" component="span">
                You can add funds to your wallet by selecting the "Add Funds"
                option in your account settings and choosing your preferred
                online payment method/wallet.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            className="acor-abut"
            expanded={expanded === "panel14"}
            onChange={handleChange("panel14")}
          >
            <AccordionSummary
              className="acor-summ"
              expandIcon={<ExpandMoreIcon className="acor-ico" />}
              aria-controls="panel4bh-content"
              id="panel4bh-header"
            >
              <Typography
                className="acor-titl"
                sx={{ flexShrink: 0 }}
                component="span"
              >
                Can I change the price of my counselling sessions after posting
                them?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="footer-link" component="span">
                Yes, once a session is posted, the price can be changed using
                the Edit button in session detail. Consider pricing carefully
                before posting your sessions.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            className="acor-abut"
            expanded={expanded === "panel15"}
            onChange={handleChange("panel15")}
          >
            <AccordionSummary
              className="acor-summ"
              expandIcon={<ExpandMoreIcon className="acor-ico" />}
              aria-controls="panel4bh-content"
              id="panel4bh-header"
            >
              <Typography
                className="acor-titl"
                sx={{ flexShrink: 0 }}
                component="span"
              >
                Is there a limit to the number of counselling sessions a
                counselee can bid on?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="footer-link" component="span">
                No, there is no specific limit. counselees can bid on multiple
                sessions based on their preferences and needs.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            className="acor-abut"
            expanded={expanded === "panel16"}
            onChange={handleChange("panel16")}
          >
            <AccordionSummary
              className="acor-summ"
              expandIcon={<ExpandMoreIcon className="acor-ico" />}
              aria-controls="panel4bh-content"
              id="panel4bh-header"
            >
              <Typography
                className="acor-titl"
                sx={{ flexShrink: 0 }}
                component="span"
              >
                How can counsellors promote their services on A2Z?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="footer-link" component="span">
                Counsellors can enhance their visibility by regularly updating
                their profiles, participating in workshops, and providing
                excellent service to receive positive reviews.
              </Typography>
            </AccordionDetails>
          </Accordion>
          {/* <Accordion
            className="acor-abut"
            expanded={expanded === "panel8"}
            onChange={handleChange("panel8")}
          >
            <AccordionSummary
              className="acor-summ"
              expandIcon={<ExpandMoreIcon className="acor-ico" />}
              aria-controls="panel4bh-content"
              id="panel4bh-header"
            >
              <Typography
                className="acor-titl"
                sx={{ flexShrink: 0 }}
                component="span"
              >
                How can I explore new counselling sessions on A2Z?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="footer-link" component="span">
                Navigate to the "Explore Sessions" section to discover available
                counselling sessions. Use filters to find sessions that match
                your preferences and needs.
              </Typography>
            </AccordionDetails>
          </Accordion> */}
          <div className="faq-counsellor-hedding">
            <h4>Student</h4>
          </div>
          <Accordion
            className="acor-abut"
            expanded={expanded === "panel17"}
            onChange={handleChange("panel17")}
          >
            <AccordionSummary
              className="acor-summ"
              expandIcon={<ExpandMoreIcon className="acor-ico" />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography
                className="acor-titl"
                sx={{ flexShrink: 0 }}
                component="span"
              >
                What happens if a counsellor rejects my bid as a counselee?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="footer-link" component="span">
                If a counsellor rejects your bid, you can explore other
                available sessions or adjust your bidding preferences for a
                better match.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            className="acor-abut"
            expanded={expanded === "panel18"}
            onChange={handleChange("panel18")}
          >
            <AccordionSummary
              className="acor-summ"
              expandIcon={<ExpandMoreIcon className="acor-ico" />}
              aria-controls="panel2bh-content"
              id="panel2bh-header"
            >
              <Typography
                className="acor-titl"
                sx={{ flexShrink: 0 }}
                component="span"
              >
                Can I bid for counselling sessions as a counselee?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="footer-link" component="span">
                Yes, counselees can bid on sessions by entering their preferred
                price. Counsellors review and respond to counselee bids,
                accepting or rejecting them.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            className="acor-abut"
            expanded={expanded === "panel19"}
            onChange={handleChange("panel19")}
          >
            <AccordionSummary
              className="acor-summ"
              expandIcon={<ExpandMoreIcon className="acor-ico" />}
              aria-controls="panel3bh-content"
              id="panel3bh-header"
            >
              <Typography
                className="acor-titl"
                sx={{ flexShrink: 0 }}
                component="span"
              >
                What types of counselling sessions are available for users on
                A2Z?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="footer-link" component="span">
                A2Z offers a broad range of counselling sessions, covering areas
                such as career growth, financial well-being, IT proficiency,
                personal development, and more.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            className="acor-abut"
            expanded={expanded === "panel20"}
            onChange={handleChange("panel20")}
          >
            <AccordionSummary
              className="acor-summ"
              expandIcon={<ExpandMoreIcon className="acor-ico" />}
              aria-controls="panel4bh-content"
              id="panel4bh-header"
            >
              <Typography
                className="acor-titl"
                sx={{ flexShrink: 0 }}
                component="span"
              >
                How can I bid for counselling sessions as a counselee?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="footer-link" component="span">
                Explore available counselling sessions, enter your preferred
                price in the bidding process, and submit your bid. Counsellors
                will review and respond, accepting or rejecting based on mutual
                agreement.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            className="acor-abut"
            expanded={expanded === "panel21"}
            onChange={handleChange("panel21")}
          >
            <AccordionSummary
              className="acor-summ"
              expandIcon={<ExpandMoreIcon className="acor-ico" />}
              aria-controls="panel4bh-content"
              id="panel4bh-header"
            >
              <Typography
                className="acor-titl"
                sx={{ flexShrink: 0 }}
                component="span"
              >
                What payment options are available for users on A2Z?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="footer-link" component="span">
                Secure payment options include debit cards, credit cards, net
                banking, UPI, and wallets. Choose the method that suits you
                best.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            className="acor-abut"
            expanded={expanded === "panel22"}
            onChange={handleChange("panel22")}
          >
            <AccordionSummary
              className="acor-summ"
              expandIcon={<ExpandMoreIcon className="acor-ico" />}
              aria-controls="panel4bh-content"
              id="panel4bh-header"
            >
              <Typography
                className="acor-titl"
                sx={{ flexShrink: 0 }}
                component="span"
              >
                How do I schedule counselling sessions with counsellors?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="footer-link" component="span">
                After your bid is accepted, collaborate with counsellors to
                schedule session times and dates. Sessions can be conducted
                within our platforms with ease.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            className="acor-abut"
            expanded={expanded === "panel23"}
            onChange={handleChange("panel23")}
          >
            <AccordionSummary
              className="acor-summ"
              expandIcon={<ExpandMoreIcon className="acor-ico" />}
              aria-controls="panel4bh-content"
              id="panel4bh-header"
            >
              <Typography
                className="acor-titl"
                sx={{ flexShrink: 0 }}
                component="span"
              >
                Is there a limit to the number of counselling sessions I can bid
                on?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="footer-link" component="span">
                No, there is no specific limit. You can bid on multiple sessions
                based on your preferences and needs.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            className="acor-abut"
            expanded={expanded === "panel24"}
            onChange={handleChange("panel24")}
          >
            <AccordionSummary
              className="acor-summ"
              expandIcon={<ExpandMoreIcon className="acor-ico" />}
              aria-controls="panel4bh-content"
              id="panel4bh-header"
            >
              <Typography
                className="acor-titl"
                sx={{ flexShrink: 0 }}
                component="span"
              >
                How can I explore new counselling sessions on A2Z?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="footer-link" component="span">
                Navigate to the "Explore Sessions" section to discover available
                counselling sessions. Use filters to find sessions that match
                your preferences and needs.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            className="acor-abut"
            expanded={expanded === "panel25"}
            onChange={handleChange("panel25")}
          >
            <AccordionSummary
              className="acor-summ"
              expandIcon={<ExpandMoreIcon className="acor-ico" />}
              aria-controls="panel4bh-content"
              id="panel4bh-header"
            >
              <Typography
                className="acor-titl"
                sx={{ flexShrink: 0 }}
                component="span"
              >
                Can I review counsellor profiles before bidding on a session?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="footer-link" component="span">
                Yes, you can review counsellor profiles to ensure a good match
                before bidding on a session. Profiles include details about
                skills, experience, and reviews from other counselees.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            className="acor-abut"
            expanded={expanded === "panel26"}
            onChange={handleChange("panel26")}
          >
            <AccordionSummary
              className="acor-summ"
              expandIcon={<ExpandMoreIcon className="acor-ico" />}
              aria-controls="panel4bh-content"
              id="panel4bh-header"
            >
              <Typography
                className="acor-titl"
                sx={{ flexShrink: 0 }}
                component="span"
              >
                Is there a minimum bidding price for counselling sessions?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="footer-link" component="span">
                Yes, rs 499 is the minimum bidding price.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            className="acor-abut"
            expanded={expanded === "panel27"}
            onChange={handleChange("panel27")}
          >
            <AccordionSummary
              className="acor-summ"
              expandIcon={<ExpandMoreIcon className="acor-ico" />}
              aria-controls="panel4bh-content"
              id="panel4bh-header"
            >
              <Typography
                className="acor-titl"
                sx={{ flexShrink: 0 }}
                component="span"
              >
                Can I view my counselling session history on A2Z?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="footer-link" component="span">
                Yes, your counselling session history is accessible on your A2Z
                dashboard, providing a record of past sessions and transactions.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            className="acor-abut"
            expanded={expanded === "panel28"}
            onChange={handleChange("panel28")}
          >
            <AccordionSummary
              className="acor-summ"
              expandIcon={<ExpandMoreIcon className="acor-ico" />}
              aria-controls="panel4bh-content"
              id="panel4bh-header"
            >
              <Typography
                className="acor-titl"
                sx={{ flexShrink: 0 }}
                component="span"
              >
                Are there any guidelines for counselee conduct on A2Z?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="footer-link" component="span">
                A2Z encourages respectful communication and professionalism.
                Familiarise yourself with our guidelines to create a positive
                counselling environment.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            className="acor-abut"
            expanded={expanded === "panel29"}
            onChange={handleChange("panel29")}
          >
            <AccordionSummary
              className="acor-summ"
              expandIcon={<ExpandMoreIcon className="acor-ico" />}
              aria-controls="panel4bh-content"
              id="panel4bh-header"
            >
              <Typography
                className="acor-titl"
                sx={{ flexShrink: 0 }}
                component="span"
              >
                Can I participate in multiple counselling sessions concurrently?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="footer-link" component="span">
                Yes, you can bid on and participate in multiple counselling
                sessions concurrently based on your availability and
                preferences.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            className="acor-abut"
            expanded={expanded === "panel30"}
            onChange={handleChange("panel30")}
          >
            <AccordionSummary
              className="acor-summ"
              expandIcon={<ExpandMoreIcon className="acor-ico" />}
              aria-controls="panel4bh-content"
              id="panel4bh-header"
            >
              <Typography
                className="acor-titl"
                sx={{ flexShrink: 0 }}
                component="span"
              >
                Can I adjust my bidding preferences after bidding on a session?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="footer-link" component="span">
                Once a bid is submitted, you cannot adjust the bidding
                preferences. Ensure your bidding preferences align with your
                expectations before submitting a bid.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            className="acor-abut"
            expanded={expanded === "panel31"}
            onChange={handleChange("panel31")}
          >
            <AccordionSummary
              className="acor-summ"
              expandIcon={<ExpandMoreIcon className="acor-ico" />}
              aria-controls="panel4bh-content"
              id="panel4bh-header"
            >
              <Typography
                className="acor-titl"
                sx={{ flexShrink: 0 }}
                component="span"
              >
                How can I add funds to my wallet on A2Z?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="footer-link" component="span">
                You can add funds to your wallet by selecting the "Add Funds"
                option in your account settings and choosing your preferred
                payment method.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            className="acor-abut"
            expanded={expanded === "panel32"}
            onChange={handleChange("panel32")}
          >
            <AccordionSummary
              className="acor-summ"
              expandIcon={<ExpandMoreIcon className="acor-ico" />}
              aria-controls="panel4bh-content"
              id="panel4bh-header"
            >
              <Typography
                className="acor-titl"
                sx={{ flexShrink: 0 }}
                component="span"
              >
                Can I give feedback about counsellors?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="footer-link" component="span">
                Yes. After each lesson, you will give a rating about your class
                (1 to 5 with 5 being the highest) and can also share your
                written feedback. You can also send us an email to
                <a href={`mailto:contact@a2z.com`} className="herf-links">
                  {` contact@a2z.com`}
                </a>{" "}
                or reach us through call/Whatsapp. You can report a lesson in
                our system if you have any problems and difficulties with a
                teacher and we will solve your problem at the earliest.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </div>
      </>
    </div>
  );
};

export default AllFaqs;
